import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { of } from 'rxjs';
import { AddReferenceComponent } from '../add-reference/add-reference.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { SwalService } from 'src/app/services/alert/swal.service';

@Component({
	selector: 'app-update-brick-old',
	templateUrl: './update-brick-old.component.html',
	styleUrls: ['./update-brick-old.component.scss'],
})
export class UpdateBrickOldComponent implements OnInit {

	brickForm: FormGroup;
	brick_key: any;
	brick: any;
	brick_image: any = false;
	presentationName: any;
	on_presentationName: any;
	presentations: any = [];
	on_presentations: any = [];
	category: any;
	type: any = 'brick';
	recommended: any = [];
	isToggleBtnChecked: any = ''
	creation_date: any
	oldVisible: any

	tinyConfs: any = {
		height: 500,
		plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount image textpattern noneditable help charmap  emoticons',
		menubar: 'file edit view insert format tools table help',
		toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
		toolbar_sticky: true,
		importcss_append: true,
		noneditable_noneditable_class: "mceNonEditable",
		toolbar_mode: 'sliding',
		contextmenu: "link table",
		templates: [
			{ title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
		],
	}
	// trade entity (superB)
	superb_PresentationName: any;
	superb_on_presentationName: any;
	// collections superb
	superbPresentations: any = [];
	superb_on_presentations: any = [];

	constructor(
		public api: ApiService,
		public formBuilder: FormBuilder,
		public loadingCtrl: LoadingController,
		public navParams: NavParams,
		public auth: AuthService,
		private http: HttpClient,
		public modalController: ModalController,
		private SwalService: SwalService
	) {
		this.brick = navParams.get('brick');
		if (this.brick) {
			//console.log("this.oldVisible", this.oldVisible, "this.brick.visible", this.brick.visible)
			this.oldVisible = this.brick.visible
			//console.log("this.oldVisible", this.oldVisible, "this.brick.visible", this.brick.visible)
			// //console.log("this.brick = ", this.brick)
		}

		if (navParams.get('type')) {
			this.type = navParams.get('type');
		}

		this.brickForm = this.formBuilder.group({
			name: [this.brick.name, [Validators.required]],
			description: [this.brick.description, [Validators.required]],
			visible: [this.brick.visible, [Validators.required]],
			type: ['brick', [Validators.required]],
			on_trade: [this.brick.on_trade, [Validators.required]],
			system_code: [this.brick.system_code],
			supplier: [this.brick.supplier],
			bar_code: [this.brick.bar_code],
			position: [this.brick.position],
			free_delivery: [this.brick.free_delivery],
		});

		if (this.type == 'brick') {
			this.api.getRef(`bricks/${this.brick.$key}/presentations`).ref
				.onSnapshot(snapshots => {
					snapshots.docChanges().forEach(element => {
						if (element.type == 'added') {
							let presentation = element.doc.data();
							presentation.$key = element.doc.id;
							this.presentations.push(presentation);
						}
					});
				})

			this.api.getRef(`bricks/${this.brick.$key}/on_presentations`).ref
				.onSnapshot(snapshots => {
					snapshots.docChanges().forEach(element => {
						if (element.type == 'added') {
							let presentation = element.doc.data();
							presentation.$key = element.doc.id;
							this.on_presentations.push(presentation);
						}
					});
				})

			// inicio superb escuchador de presentaciones
			this.api.getRef(`bricks/${this.brick.$key}/superb_presentations`).ref
				.onSnapshot(snapshots => {
					snapshots.docChanges().forEach(element => {
						if (element.type == 'added') {
							let presentation = element.doc.data();
							presentation.$key = element.doc.id;
							this.superbPresentations.push(presentation);
						}
					});
				})

			this.api.getRef(`bricks/${this.brick.$key}/superb_on_presentations`).ref
				.onSnapshot(snapshots => {
					snapshots.docChanges().forEach(element => {
						if (element.type == 'added') {
							let presentation = element.doc.data();
							presentation.$key = element.doc.id;
							this.superb_on_presentations.push(presentation);
						}
					});
				})
			// fin superb escuchador de presentaciones
		}
		this.getReferences();
	}

	ngOnInit() { }

	getReferences() {
		this.recommended = [];
		this.api.getRef(`recommended/`).ref
			.where('brick', '==', this.brick.$key)
			.get()
			.then(snapshots => {
				snapshots.forEach(element => {

					let recommended = element.data();
					recommended.$key = element.id;
					//console.log(recommended);


					this.api.getDocument('bricks', element.data().reference).then(data => {
						recommended.brick = data;
						this.recommended.push(recommended);
					}, err => {
						this.api.deleteDocument('bricks', recommended.$key)
					});

				});
			})
	}

	async loader() {
		return await this.loadingCtrl.create({
			spinner: 'lines-small',
			animated: true,
			mode: 'ios',
			translucent: false,
			cssClass: 'custom-class custom-loading',
		});
	}

	uploadedImages(file) {
		this.brick_image = {
			name: file.file.name,
			src: file.src
		};
	}

	removeImages() {
		this.brick_image = false;
	}

	addBrick() {
		this.loader().then(loader => {
			loader.present().then(async () => {
				// //console.log("addBrick() this.brick = ", this.brick)


				this.brick.name = this.brickForm.value.name;
				this.brick.description = this.brickForm.value.description;
				this.brick.visible = this.brickForm.value.visible;
				this.brick.bar_code = this.brickForm.value.bar_code;
				this.brick.supplier = this.brickForm.value.supplier;
				this.brick.free_delivery = this.brickForm.value.free_delivery;
				this.brick.on_trade = this.brickForm.value.on_trade;
				if (!this.brick.position) {
					this.brick.position = 0;
				}

				await this.api.getRef('bricks').ref
					.where('brick', '==', this.brick.$key)
					.where('type', '==', 'reference')
					.get()
					.then(data => {
						data.forEach(element => {
							this.api.updateDocument('bricks', element.id, {
								visible: this.brick.visible
							})
						});
					})
				if (this.brick.reference != undefined) {
					// //console.log("es una referencia el brick")
				} else {
					// //console.log("no es referencia el brick")
					this.brick.position = this.brickForm.value.position;
				}

				// validaciones de datos

				if (this.brick.name == '' || this.brick.name == undefined) {
					this.mensajeAlerta('error', 'Favor completar Nombre de Producto');
					loader.dismiss();
					return;
				}
				if (this.brick.image == '' || this.brick.image == undefined) {
					this.mensajeAlerta('error', 'Favor completar Imagen de Producto');
					loader.dismiss();
					return;
				}
				if (this.brick.description == '' || this.brick.description == undefined || this.brick.description.length <= 3) {
					this.mensajeAlerta('error', 'Favor completar Descripción del Producto');
					loader.dismiss();
					return;
				}

				if (!(this.presentations.length > 0) && !(this.on_presentations.length > 0)) {
					this.mensajeAlerta('error', 'Favor completar Presentaciones del Producto');
					loader.dismiss();
					return;
				} else {
					// para barlleno normal
					const flagBoolean = await this.presentations.map(item => {
						if (item.price) return item.price.includes(',')
						else return false
					})
					//console.log("flagBoolean ==>", flagBoolean)
					if (flagBoolean.includes(true)) {
						this.mensajeAlerta('error', 'Precio no valido, reemplazar (,) por (.)');
						loader.dismiss();
						return
					}

					// para barlleno ON
					const flagBooleanON = await this.on_presentations.map(item => {
						if (item.price) return item.price.includes(',')
						else return false
					})
					//console.log("flagBoolean ==>", flagBooleanON)
					if (flagBooleanON.includes(true)) {
						this.mensajeAlerta('error', 'Precio no valido, reemplazar (,) por (.)');
						loader.dismiss();
						return
					}

					// validacion para seleccion de presentacion principal
					// para barlleno normal

					const flagBooleanMain = await this.presentations.map(item => {
						if (item.main == true) return item.main
						else return false
					})

					//console.log("flagBooleanMain ==>", flagBooleanMain)
					if (!flagBooleanMain.includes(true)) {
						this.mensajeAlerta('error', 'Favor seleccionar Presentación Principal');
						loader.dismiss();
						return
					}
				}
				// fin validaciones de datos

				// el producto se coloco en invisible
				//console.log("estado del producto visible = ", !this.isToggleBtnChecked, "this.brick.visible = ", this.brick.visible)
				// if ((this.brick.visible != this.oldVisible && this.brick.visible == false) && (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT' || this.auth.countryCode == 'HN')) {
				if ((this.brick.visible != this.oldVisible && this.brick.visible == false) && (this.auth.countryCode)) {
					let presentationOld = this.presentations
					//console.log(presentationOld)
					//console.log(JSON.stringify(presentationOld))
					this.creation_date = new Date();
					let nameBrick = this.brick.name
					// remover emoji de los textos
					nameBrick = nameBrick.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
					//console.log("nameBrick", nameBrick)

					//console.log(`https://mails.barlleno.app/mails_inventoryB64.php?mode=inventoryHidden&presentations=${window.btoa(JSON.stringify(presentationOld))}&countryCode=${this.auth.countryCode}&role=${this.auth.role}&email=${this.auth.email}&creation_date=${window.btoa(this.creation_date)}&nameBrick=${window.btoa(nameBrick)}`)
					this.http.get(`https://mails.barlleno.app/mails_inventoryB64.php?mode=inventoryHidden&presentations=${window.btoa(JSON.stringify(presentationOld))}&countryCode=${this.auth.countryCode}&role=${this.auth.role}&email=${this.auth.email}&creation_date=${window.btoa(this.creation_date)}&nameBrick=${window.btoa(nameBrick)}`)
						.subscribe((response) => {
							// //console.log("enviar correo");
							this.presentations.forEach(element => {
								// //console.log(element)
								element.inventory = Number(0)
								// //console.log(element)
							});
							this.api.updateBrick(this.brick, this.presentations, this.on_presentations, this.brick_image.src, this.superbPresentations, this.superb_on_presentations).then(data => {
								loader.dismiss();
								this.SwalService.fire('¡Listo!', 'Tu producto ha sido actualizado', 'success');
								this.modalController.dismiss();
							});
						})
					// } else if ((this.brick.visible != this.oldVisible && this.brick.visible == true) && (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT' || this.auth.countryCode == 'HN')) {
				} else if ((this.brick.visible != this.oldVisible && this.brick.visible == true) && (this.auth.countryCode)) {
					let presentationOld = this.presentations
					//console.log(presentationOld)
					//console.log(JSON.stringify(presentationOld))
					this.creation_date = new Date();
					let nameBrick = this.brick.name
					nameBrick = nameBrick.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');

					//console.log(`https://mails.barlleno.app/mails_inventoryB64.php?mode=inventoryShow&presentations=${window.btoa(JSON.stringify(presentationOld))}&countryCode=${this.auth.countryCode}&role=${this.auth.role}&email=${this.auth.email}&creation_date=${window.btoa(this.creation_date)}&nameBrick=${window.btoa(nameBrick)}`)
					this.http.get(`https://mails.barlleno.app/mails_inventoryB64.php?mode=inventoryShow&presentations=${window.btoa(JSON.stringify(presentationOld))}&countryCode=${this.auth.countryCode}&role=${this.auth.role}&email=${this.auth.email}&creation_date=${window.btoa(this.creation_date)}&nameBrick=${window.btoa(nameBrick)}`)
						.subscribe((response) => {
							// //console.log("enviar correo");
							this.api.updateBrick(this.brick, this.presentations, this.on_presentations, this.brick_image.src, this.superbPresentations, this.superb_on_presentations).then(data => {
								loader.dismiss();
								this.SwalService.fire('¡Listo!', 'Tu producto ha sido actualizado', 'success');
								this.modalController.dismiss();
							});
						})
				} else {
					this.api.updateBrick(this.brick, this.presentations, this.on_presentations, this.brick_image.src, this.superbPresentations, this.superb_on_presentations).then(data => {
						loader.dismiss();
						this.SwalService.fire('¡Listo!', 'Tu producto ha sido actualizado', 'success');
						this.modalController.dismiss();
					});
				}
			})
		})
	}

	updateExplore() {
		this.loader().then(loader => {
			loader.present().then(() => {
				this.brick.name = this.brickForm.value.name;
				this.brick.description = this.brickForm.value.description;
				this.brick.visible = this.brickForm.value.visible;
				this.brick.position = this.brickForm.value.position;

				this.api.getRef('explore').ref
					.where('brick', '==', this.brick.$key)
					.where('type', '==', 'reference')
					.get()
					.then(data => {
						data.forEach(element => {
							this.api.updateDocument('explore', element.id, {
								visible: this.brick.visible
							})
						});
					})

				this.api.updateExplore(this.brick, this.brick_image.src).then(data => {
					loader.dismiss();
					this.SwalService.fire('¡Listo!', 'Tu producto ha sido agregado', 'success');
					this.modalController.dismiss();
				});
			})
		})
	}

	addPresentation() {
		this.api.addDocument(`bricks/${this.brick.$key}/presentations`, {
			presentation: this.presentationName,
			price: 0,
			reward: 0
		}).then(data => {
			this.presentationName = '';
		})

	}

	addOnPresentation() {
		this.api.addDocument(`bricks/${this.brick.$key}/on_presentations`, {
			presentation: this.on_presentationName,
			price: 0,
		}).then(data => {
			this.on_presentationName = '';
		})
	}

	changeMain(presentation, $event) {
		this.presentations.forEach(element => {
			element.main = false;
		});
		presentation.main = true;

	}

	changeOnMain(presentation, $event) {
		this.on_presentations.forEach(element => {
			element.main = false;
		});
		presentation.main = true;

	}

	changePrice(presentation, $event) {
		//console.log("changePrice", presentation)
		const { price } = presentation
		// if (price.includes(',')) {
			// price.replace(',', '.')
			// this.mensajeAlerta('error', 'Caracter no valido, utilizar Punto (.)');
			// return;
		// }
	}

	removePresentation(presentation) {
		this.SwalService.fire({
			title: 'Esta seguro que deseas eliminar?',
			text: "¡No podrás revertir esto!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, estoy seguro'
		}).then((result) => {
			if (result.value) {

				this.api.deleteDocument(`bricks/${this.brick.$key}/presentations/`, presentation.$key).then(data => {

					for (let index = 0; index < this.presentations.length; index++) {
						const element = this.presentations[index];
						if (element.$key == presentation.$key) {
							this.presentations.splice(index, 1)
						}
					}

					this.SwalService.fire(
						'Eliminado!',
						'La presentación ha sido eliminada',
						'success'
					)
				});
			}
		})
	}

	removeOnPresentation(presentation) {
		this.SwalService.fire({
			title: 'Esta seguro que deseas eliminar?',
			text: "¡No podrás revertir esto!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, estoy seguro'
		}).then((result) => {
			if (result.value) {

				this.api.deleteDocument(`bricks/${this.brick.$key}/on_presentations/`, presentation.$key).then(data => {

					for (let index = 0; index < this.on_presentations.length; index++) {
						const element = this.on_presentations[index];
						if (element.$key == presentation.$key) {
							this.on_presentations.splice(index, 1)
						}
					}

					this.SwalService.fire(
						'Eliminado!',
						'La presentación ha sido eliminada',
						'success'
					)
				});
			}
		})
	}

	async addReference() {
		const modal = await this.modalController.create({
			component: AddReferenceComponent,
			cssClass: 'addModal',
			componentProps: {
				category: this.category,
			}
		});
		modal.onDidDismiss().then(data => {
			let referenciaKey = data.data.$key
			if (!data.data.hasOwnProperty('$key')) {
				referenciaKey = data.data.objectID
			}
			if (data.data) {
				let brick = {
					brick: this.brick.$key,
					visible: data.data.visible,
					reference: referenciaKey
				}
				//console.log(brick);

				this.loader().then(loader => {
					loader.present().then(() => {
						this.api.addDocument('recommended', brick).then(data => {
							loader.dismiss();
							this.getReferences();
							this.SwalService.fire('¡Listo!', 'Tu Referencia ha sido agregada', 'success');
						});
					})
				})


			}

		})
		return await modal.present();
	}

	deleteReference(reference) {
		reference.description = unescape(encodeURIComponent(reference.description))
		reference.name = unescape(encodeURIComponent(reference.name))

		this.api.deleteDocument('recommended', reference.$key).then(data => {
			this.getReferences();
			//console.log(`https://mails.barlleno.app/mails_inventory.php?mode=deleteLog&data=${window.btoa(JSON.stringify(reference))}&role=${this.auth.role}`);
			this.http.get(`https://mails.barlleno.app/mails_inventory.php?mode=deleteLog&data=${window.btoa(JSON.stringify(reference))}&role=${this.auth.role}`)
				.subscribe((response) => {
				})
		})
	}

	visibleChange(event): void {
		// if (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT' || this.auth.countryCode == 'HN') {
		if (this.auth.countryCode) {
			const isChecked = event.detail.checked;
			//console.log(isChecked);
			if (isChecked) {
				//console.log("is check")
				this.isToggleBtnChecked = false
			}
			else {
				//console.log("Not is check")
				this.isToggleBtnChecked = true
			}
		}
	}

	mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
		Swal.fire({
			position: 'top-end',
			icon: icono,
			title: mensaje,
			showConfirmButton: false,
			timer: 3500,
			toast: true
		});
	}


	addSuperbPresentation() {
		this.api.addDocument(`bricks/${this.brick.$key}/superb_presentations`, {
			presentation: this.superb_PresentationName,
			price: 0,
			reward: 0,
			codeSap: ''
		}).then(data => {
			this.superb_PresentationName = '';
		})
	}

	addOnSuperbPresentation() {
		this.api.addDocument(`bricks/${this.brick.$key}/superb_on_presentations`, {
			presentation: this.superb_on_presentationName,
			price: 0,
			codeSap: ''
		}).then(data => {
			this.superb_on_presentationName = '';
		})
	}
	//superbPresentations
	//superb_on_presentations

	changeSuperbMainCheck(namePresentation, presentation, $event) {
		//console.log("namePresentation", namePresentation)
		this.superbPresentations.forEach(element => {
			if (element.presentation != namePresentation) {
				element.main = false;
			} else {
				// element.main = true;
			}
		});
	}

	removeSuperBPresentation(presentation) {
		this.SwalService.fire({
			title: 'Esta seguro que deseas eliminar?',
			text: "¡No podrás revertir esto!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, estoy seguro'
		}).then((result) => {
			if (result.value) {

				this.api.deleteDocument(`bricks/${this.brick.$key}/superb_presentations/`, presentation.$key).then(data => {

					for (let index = 0; index < this.superbPresentations.length; index++) {
						const element = this.superbPresentations[index];
						if (element.$key == presentation.$key) {
							this.superbPresentations.splice(index, 1)
						}
					}

					this.SwalService.fire(
						'Eliminado!',
						'La presentación Superb ha sido eliminada',
						'success'
					)
				});
			}
		})
	}

	removeSuperbOnPresentation(presentation) {
		this.SwalService.fire({
			title: 'Esta seguro que deseas eliminar?',
			text: "¡No podrás revertir esto!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, estoy seguro'
		}).then((result) => {
			if (result.value) {

				this.api.deleteDocument(`bricks/${this.brick.$key}/superb_on_presentations/`, presentation.$key).then(data => {

					for (let index = 0; index < this.superb_on_presentations.length; index++) {
						const element = this.superb_on_presentations[index];
						if (element.$key == presentation.$key) {
							this.superb_on_presentations.splice(index, 1)
						}
					}

					this.SwalService.fire(
						'Eliminado!',
						'La presentación Superb ON ha sido eliminada',
						'success'
					)
				});
			}
		})
	}

	changeSuperbVisible(presentation, $event) {
		const isChecked = $event.detail.checked;
		//console.log("changeVisible isChecked = ", isChecked);
		//console.log("this.superb_on_presentations = ", this.superb_on_presentations)
		if (isChecked) {
			//console.log("changeVisible, is check")
			presentation.visible = true;
		}
		else {
			//console.log("changeVisible, Not is check")
			presentation.visible = false;
			// if(presentation.main == false){
			presentation.inventory = 0
			// }
		}
		this.changeVisibleOnTriger(this.superb_on_presentations, presentation)

	}

	changeVisibleOnTriger(on_presentations, presentation) {
		//console.log("changeVisibleOnTriger > on_presentations = ", on_presentations, "presentation", presentation)
		let thisPresentation = presentation['presentation']
		//console.log("thisPresentation = ", thisPresentation, "thisPresentation.name = ", presentation.presentation)
		var respFind = on_presentations.find(function (elem, index) {
			if (elem.presentation == thisPresentation) {
				//console.log("encotre el valor en on_presentations")
				return true
			}
		})
		//console.log("respFind = ", respFind)
		if (respFind) {
			respFind.visible = presentation.visible
		}
	}

	// 
	changeSuperbMain(presentation, $event) {
		this.superbPresentations.forEach(element => {
			element.main = false;
		});
		presentation.main = true;
	}

	changeOnSuperbMain(presentation, $event) {
		this.superb_on_presentations.forEach(element => {
			element.main = false;
		});
		presentation.main = true;
	}
	// 

}
