import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule, FirebaseApp } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireFunctionsModule } from 'angularfire2/functions';
import { firebaseConfig } from './../environments/environment'
import { ImageUploadModule } from "angular2-image-upload";
import { AddBrickComponent } from './components/add-brick/add-brick.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdateBrickComponent } from './components/update-brick/update-brick.component';
import { OrderComponent } from './components/order/order.component';
import { AgmCoreModule } from '@agm/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CategoryComponent } from './components/category/category.component';
import { AuthGuard } from './guards/auth.guard';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AddReferenceComponent } from './components/add-reference/add-reference.component';
import { BrickPreviewComponent } from './components/brick-preview/brick-preview.component';
import { PartnerComponent } from './components/partner/partner.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountComponent } from './components/account/account.component';
import { ChatComponent } from './components/chat/chat.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { AddCouponComponent } from './components/add-coupon/add-coupon.component';
import { AddBrickMixologyComponent } from 'src/app/components/add-brick-mixology/add-brick-mixology.component';
import { NgCalendarModule } from 'ionic2-calendar';
import { AddReferenceMixoComponent } from './components/add-reference-mixo/add-reference-mixo.component';
import { AddBlogComponent } from './components/add-blog/add-blog.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { AddBrickOldComponent } from './components/add-brick-old/add-brick-old.component';
import { UpdateBrickOldComponent } from './components/update-brick-old/update-brick-old.component';
import { PreOrderComponent } from './components/pre-order/pre-order.component';
import { NgxMaskModule } from 'ngx-mask';
import { BrickChangeComponent } from './components/brick-change/brick-change.component';

@NgModule({
    declarations: [
        AppComponent,
        AddBrickComponent,
        UpdateBrickComponent,
        OrderComponent,
        CategoryComponent,
        AddReferenceComponent,
        BrickPreviewComponent,
        PartnerComponent,
        AddBrickMixologyComponent,
        AccountComponent,
        ChatComponent,
        CouponComponent,
        AddCouponComponent,
        AddReferenceMixoComponent,
        AddBlogComponent,
        AddBrickOldComponent,
        UpdateBrickOldComponent,
        PreOrderComponent,
        BrickChangeComponent
    ],
    entryComponents: [
        AddBrickComponent,
        UpdateBrickComponent,
        OrderComponent,
        CategoryComponent,
        AddReferenceComponent,
        BrickPreviewComponent,
        PartnerComponent,
        AddBrickMixologyComponent,
        AccountComponent,
        ChatComponent,
        CouponComponent,
        AddCouponComponent,
        AddReferenceMixoComponent,
        AddBlogComponent,
        AddBrickOldComponent,
        UpdateBrickOldComponent,
        PreOrderComponent,
        BrickChangeComponent
    ],
    imports: [
        BrowserModule,
        NgxMaskModule.forRoot(),
        IonicModule.forRoot({
            mode: 'ios',
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ImageUploadModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBBURLg6G2RMj66jj-iDTAhMVDQxRRel9w',
            libraries: ['places']
        }),
        EditorModule,
        BrowserAnimationsModule,
        NgxChartsModule,
        NgCalendarModule,
        DataTablesModule,
        NgxCsvParserModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        AuthGuard
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
