import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFirestoreDocument } from 'angularfire2/firestore';
import { DataOrder, OrderPoints } from 'src/app/interfaces/orderPoints';
import { Order } from '../../interfaces/order.interface';
import { DataOrderClient, Client } from '../../interfaces/orderPoints';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private firebase: AngularFirestore
  ) { }

  // Variable para obtener el país
  private country = localStorage.getItem('countryCode');

  // Conexión para obtener las cuentas clientes por país
  private reportByDate = this.firebase.collection('accounts', ref => ref
    .where('current_country', '==', this.country)
  );

  // Conexión para obtener cuenta por id
  conectionById(id: string): AngularFirestoreDocument {
    return this.firebase.collection('accounts').doc(id);
  }

  private pointsRef = (start: any, end: any) => {
    return this.firebase.collection('orders', ref => ref
      .where('countryCode', '==', this.country)
      .where('status', '==', 'delivered')
      .where('added_date', '>=', start)
      .where('added_date', '<=', end)
    );
  }

  // Obtenemos cuentas inactivas
  async getInactiveUsers() {
    const inactiveAccounts = [];

    await this.reportByDate.get().toPromise().then(
      snap => snap.forEach(snapChild => {
        inactiveAccounts.push({
          id: snapChild.id,
          ...snapChild.data()
        })
      })
    );

    return inactiveAccounts;
  }

  // Obtener ordenes por fechas
  async getTimeOrders(start: any, end: any): Promise<DataOrderClient[]>{
    const totalOrders: any = [];

    await this.firebase.collection('orders', ref => ref
      .where('countryCode', '==', this.country)
      .where('added_date', '>=', start)
      .where('added_date', '<=', end)
    ).get().toPromise().then(
      orders => orders.forEach( (order) => {
        totalOrders.push(order.data())
      })
    );
    
    const data = await this.getDataClient(totalOrders);
    return data;
  }
  
  // Obtenemos ordenes pagadas con puntos
  async getOrdersPoints(start: any, end: any): Promise<OrderPoints[]> {
    let orders = [];
    const ordersTemp = [];
    const ref = this.pointsRef(start, end);

    // Obtenemos toda la data y filtramos solo pagos con puntos
    await ref.get().toPromise().then(
      snap => snap.forEach(snapChild => {
        // //console.log(snapChild)
        const order = snapChild.data();
        if (this.extractOnlyClub(order as DataOrder)) ordersTemp.push(order)
      })
    );

    // Agregamos a ordenes la data de cliente
    await this.getDataClient(ordersTemp).then(
      response => orders = response
    );

    return orders
  }

  // Filtra las ordenes pagadas con puntos
  extractOnlyClub(order: DataOrder): boolean {
    let isClub: boolean = false;
    order.bricks.forEach(
      element => isClub = element.type == 'club' ? true : false
    );
    return isClub;
  }

  // Obtiene data de cliente y agrega a orden
  async getDataClient(orders: DataOrder[]) {
    let ordersData = [];

    for await (const order of orders) {
      await this.conectionById(order.account).get().toPromise().then(
        response => {
          ordersData.push({
            client: response.data(),
            dataOrder: order
          });
        }
      )
    }
    return ordersData;
  }

}
