import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { DataOrderClient } from 'src/app/interfaces/orderPoints';
import Swal from 'sweetalert2';
import { AuthService } from '../auth/auth.service';
import { ReportsService } from '../reports/reports.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private firebase: AngularFirestore,
    private authService: AuthService,
    private reportService: ReportsService

  ) { }

  private ordersRef = this.firebase.collection('orders');
  private countryCode = localStorage.getItem('countryCode');

  async getScoreAppService(storeKey = null): Promise<DataOrderClient[]> {
    const orders: any = [];
    let dataOrderClient: DataOrderClient[] = [];

    if (storeKey != null) {
      await this.firebase.collection('orders', ref => ref
        .where('serviceExperience', '>=', 0)
        .where('storeKey', '==', storeKey)
        .where('countryCode', '==', this.authService.countryCode)
      ).get().toPromise().then(
        (response) => response.forEach(
          snap => orders.push(snap.data())
        )
      );
    } else {
      await this.firebase.collection('orders', ref => ref
        .where('serviceExperience', '>=', 0)
        .where('countryCode', '==', this.authService.countryCode)
      ).get().toPromise().then(
        (response) => response.forEach(
          snap => orders.push(snap.data())
        )
      );
    }

    dataOrderClient = await this.reportService.getDataClient(orders)

    orders.forEach(order => {
      order.schedule_date = order.schedule_date.toDate();
      order.schedule_time = order.schedule_time.toDate();
      order.appExperience = Number(order.appExperience);
      order.serviceExperience = Number(order.serviceExperience);
    });

    return dataOrderClient;
  }

  async getOrderById(idOrder: number) {
    let order: any;
    await this.firebase.collection('orders', ref => ref
      .where('number', '==', idOrder)
    ).get().toPromise().then(
      snap => snap.forEach(
        snapChild => order = snapChild.data()
      )
    );
    //console.log("OrdersService = ", order)
    return order;
  }


  updateOrder(order: any, factura: string) {
    if (factura.length != 6) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Factura debe tener 6 digitos',
        showConfirmButton: false,
        timer: 3500,
        toast: true
      })
      return false;
    }

    this.ordersRef.doc(order).update({
      idBill: factura
    }).then(
      response => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Factura asignada con éxito',
          showConfirmButton: false,
          timer: 1000
        })
      }
    )
  }

}
