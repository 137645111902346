import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import Swal from 'sweetalert2';

@Injectable({
	providedIn: 'root'
})
export class SwalService {

	constructor() { }

	fire(title, html = '', icon = 'info', ...parameters): Promise<any> {
		// //console.log("arguments.length", arguments.length, "arguments = ", arguments)
		if (arguments.length == 3) {
			let iconGif = '' // SweetAlertIcon = 'success' | 'error' | 'warning' | 'info' | 'question';
			switch (icon) {
				case 'success':
					iconGif = 'check.gif'
					break;
				case 'error':
					iconGif = 'error.gif'
					break;

				case 'warning':
					iconGif = 'warning.gif'
					break;

				case 'info':
					iconGif = 'info.gif'
					break;

				default:
					iconGif = 'info.gif'
					break;
			}
			Swal.fire({
				html:
					`<div class="" style="display: flex;justify-content: center; align-items: center;"> <img style="width: 5.5em;" src="../assets/icons/${iconGif}" alt=""></div>` +
					`<h3 class="" id="swal2-title" style="display: flex; justify-content: center; align-items: center;">${title}</h3>` +
					`<div id="swal2-content" style="display: block;">${html}</div>`,
				confirmButtonText: 'Ok'
			})
			return;
		} else if (arguments.length == 2) {
			Swal.fire({
				html:
					`<h3 class="" id="swal2-title" style="display: flex; justify-content: center; align-items: center;">${title}</h3>` +
					`<div id="swal2-content" style="display: block;">${html}</div>`,
				confirmButtonText: 'Ok'
			})
		} else if (arguments.length == 1) {
			let iconGif = '' // SweetAlertIcon = 'success' | 'error' | 'warning' | 'info' | 'question';
			switch (title['icon']) {
				case 'success':
					iconGif = 'check.gif'
					break;
				case 'error':
					iconGif = 'error.gif'
					break;

				case 'warning':
					iconGif = 'warning.gif'
					break;

				case 'info':
					iconGif = 'info.gif'
					break;

				default:
					iconGif = 'info.gif'
					break;
			}
			return new Promise(function (resolve, reject) {
				Swal.fire({
					html:
					`<div class="" style="display: flex;justify-content: center; align-items: center;"> <img style="width: 5.5em;" src="../assets/icons/${iconGif}" alt=""> </div>` +
					`<h3 class="" id="swal2-title" style="display: flex; justify-content: center; align-items: center;">${title['title']}</h3>`+
					`<div id="swal2-content" style="display: block;">${title['text']}</div>`,
					showCancelButton: title['showCancelButton'] ? title['showCancelButton'] : false,
					confirmButtonColor: title['confirmButtonColor'],
					cancelButtonColor: title['cancelButtonColor'],
					confirmButtonText: title['confirmButtonText'] ? title['confirmButtonText'] : 'OK'
				}).then((result) => {
					resolve(result)
				})
			})
		}
	}
}
