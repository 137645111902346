import { element } from 'protractor';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';
import { ApiService } from '../api/api.service';
const identifier = "token";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    arr: any[];
    currencyTemp: any;
    countryCodeTemp: any;
    flag: boolean = false
    // role: any;
    flagSuccess: boolean = false;
    routerNotAccesible: boolean = false;

    constructor(
        public database: AngularFireDatabase,
        private angularAuth: AngularFireAuth,
        private router: Router,
        public db: AngularFirestore,
        public auth: AuthService,

    ) {
        // this.setUp();
    }

    token: string = this.getTokenFromLocalStorage();
    countryCode: string = this.getCountryFromLocalStorage();
    currency: string = this.getCurrencyFromLocalStorage();
    role: string = this.getRoleFromLocalStorage();
    email: string = this.getEmailFromLocalStorage();
    storeKey: string = this.getStoreKeyFromLocalStorage();
    nameStore: string = this.getNameStoreFromLocalStorage();
    mainStore: string = this.getMainStoreFromLocalStorage();
    storeCode : string = this.getCodeStoreFromLocalStorage();

    setUp() {
        this.token = this.getTokenFromLocalStorage();
        this.countryCode = this.getCountryFromLocalStorage();
        this.currency = this.getCurrencyFromLocalStorage();

        this.angularAuth.authState.subscribe((firebaseUser) => {
            if (firebaseUser) {

                this.db.collection('configurations').ref
                    .where('email', '==', firebaseUser.email)
                    .get()
                    .then(conf_response => {
                        if (!conf_response.empty) {
                            localStorage.setItem('countryCode', conf_response.docs[0].data().countryCode)
                            localStorage.setItem(identifier, firebaseUser.uid);
                            this.token = firebaseUser.uid;
                            this.countryCode = conf_response.docs[0].data().countryCode;
                            this.currency = conf_response.docs[0].data().currency;

                            // //console.log(conf_response.docs[0].data())
                            // //console.log(conf_response.docs[0].data().hasOwnProperty('users'))
                            if (conf_response.docs[0].data().hasOwnProperty('users')) {
                                conf_response.docs[0].data().users.forEach(element => {
                                    // //console.log(element.email)
                                    // //console.log(element.role)
                                    if (element.email == firebaseUser.email) {
                                        localStorage.setItem('role', element.role)
                                        localStorage.setItem('email', element.email)
                                    }
                                });
                            } else {
                                localStorage.setItem('role', "404")
                            }
                        } else {
                            this.logOut();
                        }
                    })
            } else {
                localStorage.removeItem(identifier)
                this.token = null;
            }
        })
    }

    //Verifica los roles de los usuarios
    privateRouter (path:string){

        if(this.countryCode == "DO"){
            //Metodo para DO

            // permisos para rol 0
            // if( this.role =="0"){
            //     if(this.router.url.includes("/events")){
            //         this.redirectPrivate()
            //     }

            //     //Permisos para rol 1
            // } else 
            if( this.role== "1"){
                if(this.router.url =="/verified" || this.router.url.includes("/beerday") || this.router.url.includes("/push") || this.router.url.includes("/business-accounts") || this.router.url.includes("/business-accounts-validate")){
                    this.redirectPrivate()
                } else if (this.router.url.includes("/events")){
                    this.redirectPrivate()

                }

                // permisos para rol 2
            }else if( this.role =="2"){
                if( this.router.url.includes("/monitor-v2") || this.router.url == "/verified-requests" || this.router.url.includes("/suppliers")|| this.router.url.includes("/delivery")){
                    this.redirectPrivate()

                } else if(this.router.url.includes("/business-accounts")|| this.router.url.includes("/business-accounts-validate")|| this.router.url.includes("/reports-v2") || this.router.url.includes("/events")){
                    this.redirectPrivate()

                }
            
            //Permisos para rol 3
            } else if( this.role =="3"){
                if( this.router.url == "/verified-requests" || this.router.url =="/verified" || this.router.url.includes("/suppliers")|| this.router.url.includes("/delivery")){
                    this.redirectPrivate()

                } else if(this.router.url.includes("/beerday")|| this.router.url.includes("/push")|| this.router.url.includes("/accounts") || this.router.url.includes("/coupons") || this.router.url.includes("/settings")){
                    this.redirectPrivate()

                } else if(this.router.url.includes("/business-accounts")|| this.router.url.includes("/business-accounts-validate")|| this.router.url.includes("/support") || this.router.url.includes("/events")){
                    this.redirectPrivate()

                }
            
            //Permisos para rol 4 
            } else if( this.role =="4"){
                if( this.router.url.includes("/suppliers") || this.router.url.includes("/reports-v2")  ){
                    this.redirectPrivate()

                } else if( this.router.url.includes("/events")){
                    this.redirectPrivate()

                }
            
            //Permisos para rol 5 
            } else if( this.role =="5"){
                if(this.router.url.includes("/section/dashboard")|| this.router.url.includes("/search-brick")|| this.router.url.includes("/section/wines") || this.router.url.includes("/section/liquors")){
                    this.redirectPrivate()

                } else  if(this.router.url.includes("/section/beers")|| this.router.url.includes("/section/non-alcoholic")|| this.router.url.includes("/explore-section") || this.router.url.includes("/section/toeat")){
                    this.redirectPrivate()

                }  else  if(this.router.url.includes("/section/digestic")|| this.router.url.includes("/section/club")|| this.router.url.includes("/chats") || this.router.url.includes("/chats-pendientes")){
                    this.redirectPrivate()

                }  else  if(this.router.url == "/verified-requests"|| this.router.url == "/verified" || this.router.url.includes("/suppliers") || this.router.url.includes("/delivery")){
                    this.redirectPrivate()

                }  else  if(this.router.url.includes("/beerday")|| this.router.url.includes("/push")|| this.router.url.includes("/accounts") || this.router.url.includes("/business-accounts")){
                    this.redirectPrivate()

                } else  if(this.router.url.includes("/business-accounts-validate")|| this.router.url.includes("/coupons")|| this.router.url.includes("/settings") ){
                    this.redirectPrivate()

                } 
            }else {
                this.redirectPrivate()

            }
        } else if (this.countryCode == "SV" || this.countryCode== "HN"){
            //Metodo para SV y HN
            if( this.role== "1"){
                if(this.router.url.includes("/section/toeat") || this.router.url.includes("/section/digestic") || this.router.url =="/verified" || this.router.url.includes("/push") || this.router.url.includes("/business-accounts")){
                    this.redirectPrivate()

                } else if (this.router.url.includes("/business-accounts-validate") ){
                    this.redirectPrivate()

                }
            } else if( this.role== "2"){
                 if(  this.router.url =="/monitor"  || this.router.url.includes("/section/toeat") || this.router.url.includes("/section/digestic") || this.router.url == "/verified" || this.router.url.includes("/suppliers")){
                     this.redirectPrivate()

                 } else  if(this.router.url.includes("/delivery") || this.router.url.includes("/business-accounts") || this.router.url.includes("/business-accounts-validate") || this.router.url.includes("/reports-v2") ){
                     this.redirectPrivate()

                 }
            } else if( this.role== "3"){
                if( this.router.url.includes("/section/toeat") || this.router.url.includes("/section/digestic") || this.router.url == "/verified-requests" || this.router.url == "/verified" || this.router.url.includes("/suppliers")){
                    this.redirectPrivate()

                } else  if(this.router.url.includes("/delivery")|| this.router.url.includes("/beerday") ||this.router.url.includes("/push")|| this.router.url.includes("/business-accounts") || this.router.url.includes("/business-accounts-validate") ){
                    this.redirectPrivate()

                } else  if(this.router.url.includes("/coupons") || this.router.url.includes("/settings") || this.router.url.includes("/support") ){
                    this.redirectPrivate()

                }
            } else if( this.role== "4"){
                if( this.router.url.includes("/section/toeat") || this.router.url.includes("/section/digestic") ||this.router.url.includes("/suppliers") || this.router.url.includes("/reports-v2")){
                    this.redirectPrivate()

                } 
            } else if( this.role== "5"){
                if( !this.router.url.includes("/support") ||  !(this.router.url=="/monitor-v3") ||  !this.router.url.includes("/events")){
                    this.redirectPrivate()

                } 
            } else if( this.role== "6"){
                if( !this.router.url.includes("/support") ||  !this.router.url.includes("/monitor-v3") ||  !this.router.url.includes("/events")){
                    this.redirectPrivate()

                } 
            } else if( this.role== "7"){
                if( this.router.url.includes("/explore-section") || this.router.url.includes("/section/toeat") || this.router.url.includes("/section/digestic")  || this.router.url.includes("/section/club")  || this.router.url.includes("/chats")  ){
                    this.redirectPrivate()

                } else  if( this.router.url.includes("/chats-pendientes") || this.router.url == "/verified-requests" || this.router.url =="/verified" || this.router.url.includes("/beerday")  || this.router.url.includes("/push")  ){
                    this.redirectPrivate()

                }  else  if( this.router.url.includes("/accounts") || this.router.url.includes("/business-accounts-validate") || this.router.url.includes("/coupons") ){
                    this.redirectPrivate()

                } 
            } else if( this.role== "8"){
                if( !this.router.url.includes("/support") || !this.router.url.includes("/monitor-v3") || !this.router.url.includes("/events")  || !this.router.url.includes("/reports-event") ){
                    this.redirectPrivate()

                }
            }


        }else {
            //Metodo para los demas paises
            if( this.role== "1"){
                if(this.router.url == "/verified" || this.router.url.includes("/push") || this.router.url.includes("/business-accounts") || this.router.url.includes("/push") || this.router.url.includes("/business-accounts")){
                    this.redirectPrivate()

                } else if (this.router.url.includes("/business-accounts-validate") ){
                    this.redirectPrivate()

                }
            } else if( this.role== "2"){
                 if(this.router.url.includes("/monitor-v2") || this.router.url == "/verified"|| this.router.url.includes("/suppliers") || this.router.url == "/verified-requests" || this.router.url.includes("/suppliers")){
                    this.redirectPrivate()

                } else  if(this.router.url.includes("/delivery") || this.router.url.includes("/business-accounts") || this.router.url.includes("/business-accounts-validate") || this.router.url.includes("/reports-v2") ){
                    this.redirectPrivate()
                    
                } 
            } else if( this.role== "3"){
                if( this.router.url == "/verified-requests" || this.router.url == "/verified" || this.router.url=="/verified-requests" || this.router.url.includes("/suppliers")){
                    this.redirectPrivate()

                } else  if(this.router.url.includes("/delivery")|| this.router.url.includes("/beerday") ||this.router.url.includes("/push")|| this.router.url.includes("/business-accounts") || this.router.url.includes("/business-accounts-validate") ){
                    this.redirectPrivate()

                } else  if(this.router.url.includes("/coupons") || this.router.url.includes("/settings") || this.router.url.includes("/support") ){
                    this.redirectPrivate()

                }
            } else if( this.role== "4"){
                if( this.router.url.includes("/suppliers") || this.router.url.includes("/reports-v2")){
                    this.redirectPrivate()

                } 
            } else if( this.role== "5"){
                if( !this.router.url.includes("/monitor-v2") || !this.router.url.includes("/reports-v2") || !(this.router.url =="/verified-requests")){
                    this.redirectPrivate()

                }
            } 
        }
    }

    redirectPrivate(){
        this.routerNotAccesible = true;
        this.router.navigate(["/permission-denied"]).then(() => {
            window.location.reload();
          });
    }

    getTokenFromLocalStorage(): string {
        return localStorage.getItem(identifier)
    }

    getCountryFromLocalStorage(): string {
        return localStorage.getItem('countryCode')
    }

    getRoleFromLocalStorage(): string {
        return localStorage.getItem('role')
    }

    getEmailFromLocalStorage(): string {
        return localStorage.getItem('email')
    }

    getCurrencyFromLocalStorage() {
        return localStorage.getItem('currency')
    }

    getStoreKeyFromLocalStorage() {
        return localStorage.getItem('storeKey')
    }

    getNameStoreFromLocalStorage() {
        return localStorage.getItem('nameStore')
    }

    getMainStoreFromLocalStorage() {
        return localStorage.getItem('mainStore')
    }

    getCodeStoreFromLocalStorage() {
        return localStorage.getItem('storeCode')
    }

    login(logindata) {
        return new Promise((resolve, reject) => {
            this.angularAuth.auth.signInWithEmailAndPassword(logindata.email, logindata.password).then((data) => {

                // //console.log("login(logindata) data");
                // //console.log(data);

                resolve();
            }, (err) => {
                reject(err);
            })
        });
    }

    validateAccount(logindata) {
        return new Promise(async resolve => {

            this.token = '';
            this.countryCode = '';
            this.currency = '';

            this.angularAuth.authState.subscribe((firebaseUser) => {
                if (firebaseUser) {

                    localStorage.setItem(identifier, firebaseUser.uid);
                    this.token = firebaseUser.uid;

                    this.db.collection('configurations')
                        .get()
                        .toPromise()
                        .then(async (querySnapshot) => {
                            this.arr = [];
                            querySnapshot.forEach(doc => {
                                var obj = JSON.parse(JSON.stringify(doc.data()))
                                this.arr.push(obj);
                            });
                            // //console.log(this.arr)
                            // //console.log(typeof (this.arr))
                            // //console.log(this.arr.length)
                            if (this.arr.length > 0 && this.flag == false) {
                                this.currencyTemp = ''
                                this.countryCodeTemp = ''
                                this.arr.forEach(async conf_response => {
                                    let doc = conf_response
                                    if (this.flag == false) {
                                        for (const property in doc) {
                                            if (property === 'currency') {
                                                this.currencyTemp = doc[property]
                                            }
                                            if (property === 'countryCode') {
                                                this.countryCodeTemp = doc[property]
                                            }
                                            if (property === 'users' && this.flag == false) {
                                                for (const property2 in doc[property]) {
                                                    // //console.log(doc[property][property2].email)
                                                    // //console.log(doc[property][property2].role)
                                                    // //console.log(doc[property][property2].email === logindata.email)
                                                    if (doc[property][property2].email === logindata.email) {
                                                        this.flag = true
                                                        this.role = doc[property][property2].role
                                                        this.email = doc[property][property2].email
                                                        break
                                                    }
                                                }
                                            }
                                        }
                                    }
                                });
                            } else {
                                // //console.log("arr is Empty")
                                let respSaveLog = await this.saveLogLogin(this.token, 'fracaso')
                                resolve("fracaso");
                            }
                            if (this.flag) {
                                // //console.log(this.flag, logindata.email, logindata.password)
                                // var result = await this.angularAuth.auth.signInWithEmailAndPassword(logindata.email, logindata.password).then((data) => {
                                this.flagSuccess = true
                                localStorage.setItem('countryCode', this.countryCodeTemp)
                                localStorage.setItem('currency', this.currencyTemp)
                                localStorage.setItem('role', this.role)
                                localStorage.setItem('email', this.email)
                                // //console.log(data)
                                // }, (err) => {
                                //     this.flagSuccess = false
                                // })
                                if (this.flagSuccess) {
                                    let respSaveLog = await this.saveLogLogin(this.token, 'logIn - exito')
                                    resolve("exito");
                                } else {
                                    let respSaveLog = await this.saveLogLogin(this.token, 'logIn - fracaso')
                                    resolve("fracaso");
                                }
                            } else {
                                // //console.log("fin de ejecucions")
                                let respSaveLog = await this.saveLogLogin(this.token, 'logIn - fracaso')
                                resolve("fracaso");
                            }
                        })
                } else {
                    localStorage.removeItem(identifier)
                    this.token = null;
                }

            })

        });
    }


    // grabar log en base sobre el acceso
    saveLogLogin(token, message) {
        return new Promise((resolve, reject) => {
            //console.log("ingreso a saveLogLogin")
            // comprobar que existe la cuenta en users-stores
            this.db.collection(`users-stores`).doc(token)
                .get()
                .toPromise()
                .then((snapshot) => {
                    let doc = snapshot.data();
                    doc.$key = snapshot.id;
                    // agregando documento a la collection log init session
                    let batch = this.db.firestore.batch();
                    let obj = {
                        message,
                        date_session: new Date(),
                        uid: snapshot.id
                    }
                    let key_session = this.db.createId();
                    batch.set(this.db.firestore.collection(`users-stores/${snapshot.id}/logs_session/`).doc(key_session),
                        obj, { merge: true }
                    );
                    batch.commit().then(data => {
                        //console.log("commit")
                        resolve('success');
                    })
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }

    // // funcion original
    // login(logindata) {
    //     return new Promise((resolve, reject) => {
    //         this.db.collection('configurations').ref
    //             .where('email', '==', logindata.email)
    //             .get()
    //             .then(conf_response => {
    //                 if (!conf_response.empty) {
    //                     this.angularAuth.auth.signInWithEmailAndPassword(logindata.email, logindata.password).then((data) => {
    //                         localStorage.setItem('countryCode', conf_response.docs[0].data().countryCode)
    //                         localStorage.setItem('currency', conf_response.docs[0].data().currency)

    //                     // //console.log(conf_response.docs[0].data().hasOwnProperty('users'))
    //                         if (conf_response.docs[0].data().hasOwnProperty('users')) {
    //                             conf_response.docs[0].data().users.forEach(element => {
    //                                 // //console.log(conf_response.docs[0].data())
    //                                 // //console.log(element.email)
    //                                 // //console.log(element.role)
    //                                 if (element.email == logindata.email) {
    //                                     localStorage.setItem('role', element.role)
    //                                 }
    //                             });
    //                         } else {
    //                             localStorage.setItem('role', "404")
    //                         }
    //                         resolve();
    //                     }, (err) => {
    //                         reject(err);
    //                     })

    //                 } else {
    //                     reject();
    //                 }
    //             })
    //     });
    // }

    //pauso desarrollo para resolver situacion de Zona Horaria en DO
    // login(logindata) {
    //     return new Promise(async resolve => {
    //         this.db.collection('configurations')
    //             .get()
    //             .toPromise()
    //             .then(async (querySnapshot) => {
    //                 this.arr = [];
    //                 querySnapshot.forEach(doc => {
    //                     var obj = JSON.parse(JSON.stringify(doc.data()))
    //                     this.arr.push(obj);
    //                 });
    //             // //console.log(this.arr)
    //             // //console.log(typeof (this.arr))
    //             // //console.log(this.arr.length)
    //                 if (this.arr.length > 0 && this.flag == false) {
    //                     this.currencyTemp = ''
    //                     this.countryCodeTemp = ''
    //                     this.arr.forEach(async conf_response => {
    //                         let doc = conf_response
    //                         if (this.flag == false) {
    //                             for (const property in doc) {
    //                                 if (property === 'currency') {
    //                                     this.currencyTemp = doc[property]
    //                                 }
    //                                 if (property === 'countryCode') {
    //                                     this.countryCodeTemp = doc[property]
    //                                 }
    //                                 if (property === 'users' && this.flag == false) {
    //                                     for (const property2 in doc[property]) {
    //                                     // //console.log(doc[property][property2].email)
    //                                     // //console.log(doc[property][property2].role)
    //                                     // //console.log(doc[property][property2].email === logindata.email)
    //                                         if (doc[property][property2].email === logindata.email) {
    //                                             this.flag = true
    //                                             this.role = doc[property][property2].role
    //                                             break
    //                                         }
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     });
    //                 } else {
    //                 // //console.log("arr is Empty")
    //                     resolve("fracaso");
    //                 }
    //                 if (this.flag) {
    //                 // //console.log(this.flag, logindata.email, logindata.password)
    //                     var result = await this.angularAuth.auth.signInWithEmailAndPassword(logindata.email, logindata.password).then((data) => {
    //                         this.flagSuccess = true
    //                         localStorage.setItem('countryCode', this.countryCodeTemp)
    //                         localStorage.setItem('currency', this.currencyTemp)
    //                         localStorage.setItem('role', this.role)
    //                     // //console.log(data)
    //                     }, (err) => {
    //                         this.flagSuccess = false
    //                     })
    //                     if (this.flagSuccess) {
    //                         resolve("exito");
    //                     } else {
    //                         resolve("fracaso");
    //                     }
    //                 } else {
    //                 // //console.log("fin de ejecucions")
    //                     resolve("fracaso");
    //                 }
    //             })
    //     });
    // }

    logOut() {
        return this.angularAuth.auth.signOut().then(() => {
            this.token = null;
            this.router.navigate(['login']);
        });
    }

    resetPassword(emailAddress) {
        let auth = firebase.auth();
        return new Promise((resolve, reject) => {
            auth.sendPasswordResetEmail(emailAddress).then(function () {
                resolve(true);
            }).catch(function (error) {
                reject(error);
            });
        });
    }

    getCurrentUser() {
        return firebase.auth().currentUser;
    }

    private generateUUID(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

}
