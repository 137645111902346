import { element } from 'protractor';
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { ApiService } from './services/api/api.service';
import { HttpClient } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import Swal from 'sweetalert2';
import { RealtimeApiService } from './services/realtimeApi/realtime-api.service';
import { RouterHistoryService, WindowService } from './services';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    account: any = false;
    flag: boolean = true
    audio: any;
    isShowToast: any = false
    // variables del manejo de chats pendientes de leer
    isShowToastSMS: any = false
    unreadMessagesCount: any;
    unreadNotificationsCount: any = 0;
    conversationList: any;
    conversationsInfo: any;
    sender_id: any;
    // sonido chat
    audioSMS: any;

    // Via RouterHistoryService
    previousUrlViaRouterHistoryService$ = this.routerHistoryService.previousUrl$;
    currentUrlViaRouterHistoryService$ = this.routerHistoryService.currentUrl$;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        public api: ApiService,
        private http: HttpClient,
        public auth: AuthService,
        public dataProvider: RealtimeApiService,
        public toastController: ToastController,
        private routerHistoryService: RouterHistoryService,
        private windowService: WindowService
    ) {
        this.initializeApp();
        this.audio = new Audio();
        this.audio.src = 'assets/128.mp3';
        this.audio.load();
        // sonido SMS
        this.audioSMS = new Audio();
        this.audioSMS.src = 'assets/135.mp3';
        this.audioSMS.load();

        // configurando los sender de barlleno internacional
        if (this.auth.countryCode == 'GT') {
            this.sender_id = 'BarllenoGT';
        }
        else if (this.auth.countryCode == 'HN') {
            this.sender_id = 'BarllenoHN';
        }
        else if (this.auth.countryCode == 'DO') {
            this.sender_id = 'BarllenoDO';
        }
        else if (this.auth.countryCode == 'CO') {
            this.sender_id = 'BarllenoCO';
        } else if (this.auth.countryCode == 'SV') {
            this.sender_id = 'Barlleno';
        }

    }
    sleep = m => new Promise(r => setTimeout(r, m))

    async initializeApp() {
        // await new Promise(r => setTimeout(r, 3000));
        (async () => {
            console.time("Slept for")
            await this.sleep(3000)
            this.flag = false
        })()
        if (this.auth.countryCode) {
            // emitir sonido cuando se agrega una orden normal de barlleno
            this.api.getRef('orders').ref
                .where("status", "==", "pending")
                .where('countryCode', '==', this.auth.countryCode)
                // .orderBy('added_date', 'desc')
                // .limit(1)
                .onSnapshot(snapshots => {
                    // //console.log(snapshots.size)

                    snapshots.docChanges().forEach(element => {
                        // //console.log("data element -> consulta a la baseDatos")
                        // //console.log(element.doc.data())
                        // //console.log(element.doc.data().added_date.toDate())
                        let date = element.doc.data().added_date.toDate().getDate()
                        // //console.log("date");
                        // //console.log(date);

                        let hours = element.doc.data().added_date.toDate().getHours()
                        // //console.log("hours");
                        // //console.log(hours);

                        let minutes = element.doc.data().added_date.toDate().getMinutes()
                        // //console.log("minutes");
                        // //console.log(minutes);

                        let dateNow = new Date()
                        // //console.log("dateNow");
                        // //console.log(dateNow);

                        let hoursNow = dateNow.getHours()
                        // //console.log("hoursNow");
                        // //console.log(hoursNow);

                        let minutesNow = dateNow.getMinutes()
                        // //console.log("minutesNow");
                        // //console.log(minutesNow);

                        // //console.log(element.type)
                        // if (this.flag) {
                        //     this.flag = false
                        // } else {
                        let storeKey = element.doc.data().storeKey != undefined ? element.doc.data().storeKey : ''
                        // console.log("storeKey = ", storeKey)
                        if ((storeKey == '' && this.auth.mainStore == 'true') || storeKey == this.auth.storeKey) {
                            if (element.type == 'added' && this.flag == false) {
                                // agregando toast al monitor
                                this.presentToastWithOptions(hours, minutes < 10 ? '0' + minutes : minutes)
                            }
                        } else {
                            // console.log("orden recibida de otra tienda")
                        }
                        // }
                    });
                })
        }

        if (this.auth.role == "5") {
            // emitir sonido cuando se agrega una pre-orden / solicitud de producto
            this.api.getRef('requests-orders').ref
                .where("status", "==", "pending")
                .where('countryCode', '==', this.auth.countryCode)
                .onSnapshot(snapshots => {
                    snapshots.docChanges().forEach(element => {
                        let date = element.doc.data().added_date.toDate().getDate()
                        let hours = element.doc.data().added_date.toDate().getHours()
                        let minutes = element.doc.data().added_date.toDate().getMinutes()
                        let dateNow = new Date()
                        let hoursNow = dateNow.getHours()
                        let minutesNow = dateNow.getMinutes()
                        let typeOrder = element.doc.data().payment.type == 'pre-order' ? 'Solicitud de Compra' : 'Orden'
                        // console.log("typeOrder = ", typeOrder)
                        if (element.type == 'added' && this.flag == false) {
                            // agregando toast al monitor
                            this.presentToastWithOptions(hours, minutes < 10 ? '0' + minutes : minutes, typeOrder)
                        }
                    });
                })
        }

        // Get conversations and add/update if the conversation exists, otherwise delete from list.
        this.dataProvider.getConversations().snapshotChanges().subscribe((conversationsInfoRes) => {
            if (conversationsInfoRes) {
                // console.log("conversationsInfoRes = ", conversationsInfoRes)
                let conversationsInfo = [];
                conversationsInfo = conversationsInfoRes.map(c => {
                    let data = c.payload.val();
                    data['$key'] = c.key
                    return data;
                });

                // //console.log(conversationsInfo);

                this.conversationsInfo = null;
                this.conversationList = null;

                if (conversationsInfo.length > 0) {
                    this.conversationsInfo = conversationsInfo;
                    conversationsInfo.forEach((conversationInfo) => {

                        this.dataProvider.getConversation(conversationInfo.conversationId).snapshotChanges().subscribe((conversationRes: any) => {
                            if (conversationRes.payload.exists()) {
                                let lastMessage = conversationRes.payload.val().messages[conversationRes.payload.val().messages.length - 1];
                                let conversation = conversationRes.payload.val();
                                conversation['$key'] = conversationRes.key;
                                if (lastMessage.sender != this.sender_id && this.auth.countryCode == 'SV') {
                                    this.presentToastWithSMS()
                                    // //console.log("conversation", conversation)
                                }
                                this.addOrUpdateConversation(conversation);
                            }
                        });

                    });

                }
            }
        });
    }
    addOrUpdateConversation(conversation) {
        if (!this.conversationList) {
            this.conversationList = [conversation];
        } else {
            var index = -1;

            for (var i = 0; i < this.conversationList.length; i++) {
                if (this.conversationList[i].$key == conversation.$key) {
                    index = i;
                }
            }

            if (index > -1) {
                this.conversationList[index] = conversation;
            } else {
                this.conversationList.push(conversation);
            }
        }
        this.computeUnreadMessagesCount();
    }

    computeUnreadMessagesCount() {
        this.unreadMessagesCount = 0;
        if (this.conversationList) {
            for (var i = 0; i < this.conversationList.length; i++) {
                if (this.conversationList[i].messages[this.conversationList[i].messages.length - 1]['sender'] != this.sender_id) {
                    this.unreadMessagesCount += this.conversationList[i].messages.length - this.conversationsInfo[i].messagesRead;
                    if (this.unreadMessagesCount == 0) {
                        this.unreadMessagesCount = null;
                    } else {

                    }
                }
            }
        }
    }

    getUnreadMessagesCount() {
        if (this.unreadMessagesCount) {
            if (this.unreadMessagesCount > 0) {
                return this.unreadMessagesCount;
            }
        }
        return null;
    }

    logout() {
        this.auth.logOut();
    }

    async presentToastWithOptions(hours, minutes, txt = 'Orden') {
        if (!this.isShowToast) {
            this.audio.play();
            this.isShowToast = true
            const toast = await this.toastController.create({
                header: 'ORDEN BARLLENO',
                message: `Última ${txt} Barlleno recibida a las ` + hours + ':' + minutes + '<br>' + '<strong>VERIFICAR SINO REFRESCA MONITOR</strong>',
                position: 'middle',
                color: 'warning',
                buttons: [
                    {
                        side: 'start',
                        icon: 'wine-outline',
                        text: 'NOTIFICACION',
                        handler: () => {
                            // //console.log('Favorite clicked');	
                        }
                    }, {
                        text: 'ACEPTAR',
                        role: 'cancel',
                        handler: () => {
                            toast.onDidDismiss();
                            this.isShowToast = false
                            // //console.log('Cancel clicked');	
                        }
                    }
                ]
            });
            await toast.present();
        } else {
            // //console.log("Toast ya esta visible")	
        }
    }

    async presentToastWithSMS(hours = 0, minutes = 0) {
        // //console.log("presentToastWithSMS")
        if (!this.isShowToastSMS) {
            this.audioSMS.play();
            this.isShowToastSMS = true
            const toast = await this.toastController.create({
                header: 'CHAT BARLLENO',
                message: 'Nuevo Mensaje Sin leer ' + hours + ' :  ' + minutes + '<br>' + '<strong>VERIFICAR CHATS SIN LEER</strong>',
                position: 'middle',
                color: 'warning',
                buttons: [
                    {
                        side: 'start',
                        icon: 'wine-outline',
                        text: 'NOTIFICACION',
                        handler: () => {
                            // //console.log('Favorite clicked');	
                        }
                    }, {
                        text: 'ACEPTAR',
                        role: 'cancel',
                        handler: () => {
                            toast.onDidDismiss();
                            this.isShowToastSMS = false
                            // //console.log('Cancel clicked');	
                        }
                    }
                ]
            });
            await toast.present();
        } else {
            // //console.log("Toast ya esta visible")	
        }
    }

    // para manejar el historial de paginas visitadas
    onClick($event: MouseEvent): void {
        $event.preventDefault();
        this.windowService.nativeWindow.history.back();
    }

}
