import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController, NavParams, LoadingController } from '@ionic/angular';
import algoliasearch from 'algoliasearch';
import { algoliaConfig } from 'src/environments/environment.prod';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
	selector: 'app-brick-change',
	templateUrl: './brick-change.component.html',
	styleUrls: ['./brick-change.component.scss'],
})
export class BrickChangeComponent implements OnInit {
	bricks: any = [];
	listAddbricks: any = [];
	current_bricks: any = [];
	loading: any = true;
	lastRequest: any;
	searching: any = false;
	GeneralInfo: any;
	index_config: any
	number_order: any
	brick_delete: any
	order: any

	constructor(
		public api: ApiService,
		public modalController: ModalController,
		public navParams: NavParams,
		public loadingCtrl: LoadingController,
		public auth: AuthService
	) {
		if (this.auth.countryCode == 'SV') {
			this.index_config = 'general'
		} else {
			this.index_config = `${this.auth.countryCode}`
		}
		this.api.getDocument('configurations', this.index_config).then(data => {
			this.GeneralInfo = data;
		})

		// console.log(this.auth.countryCode);
		this.number_order = navParams.get('number_order');
		this.brick_delete = navParams.get('brick_delete');
		this.order = navParams.get('order');
		// console.log("this.order = ", this.order)

		// this.api.getRef('bricks').ref
		// 	.where('type', '==', 'brick')
		// 	.where('countryCode', '==', this.auth.countryCode)
		// 	.where('storeKey', '==', this.auth.storeKey)
		// 	.orderBy('created_at')
		// 	.limit(20)
		// 	.get()
		// 	.then(snapshots => {
		// 		this.lastRequest = snapshots.docs[snapshots.docs.length - 1];

		// 		snapshots.forEach(element => {
		// 			let brick = element.data();
		// 			brick.$key = element.id;
		// 			this.current_bricks.push(brick);
		// 		});
		// 		this.bricks = this.current_bricks;
		this.loading = false;
		// 	})
	}

	ngOnInit() { }

	// no se invoca la funcion
	loadData(event) {
		this.api.getRef('bricks').ref
			.where('type', '==', 'brick')
			.where('countryCode', '==', this.auth.countryCode)
			.where('storeKey', '==', this.auth.storeKey)
			.orderBy('created_at')
			.limit(20)
			.startAfter(this.lastRequest.data().created_at.toDate())
			.get()
			.then(snapshots => {
				this.lastRequest = snapshots.docs[snapshots.docs.length - 1];
				snapshots.forEach(element => {
					let brick = element.data();
					brick.$key = element.id;
					this.current_bricks.push(brick);
				});
				this.bricks = this.current_bricks;
				event.target.complete();
			})
	}

	selectBrick(brick) {
		brick['isChange'] = true
		this.listAddbricks.push(brick)
		// this.modalController.dismiss(
		// 	brick
		// )
	}

	search(e) {
		this.loading = true;

		let query = e.detail.value;
		if (query !== '') {
			const client = algoliasearch(algoliaConfig.appId, algoliaConfig.apiKey);
			const index = client.initIndex('bricks');

			this.searching = true;
			this.bricks = [];
			index.search(query)
				.then(async ({ hits }) => {
					// console.log("hits.length = ", hits.length, "hits == ", hits)
					for (let index = 0; index < hits.length; index++) {
						let element = hits[index]
						// hits.forEach(async element => {
						if (this.GeneralInfo.queryAlgolia == true) {
							// console.log("this.GeneralInfo.queryAlgolia = ", this.GeneralInfo.queryAlgolia, element)
							if (element['countryCode'] == this.auth.countryCode && element['storeKey'] == this.auth.storeKey) {
								//console.log(element);
								if (!element.hasOwnProperty('evento') || (element.hasOwnProperty('evento') && element['evento'] != true)) {
									// console.log("response de algolia = ", element);
									await this.api.getAllDocuments(`bricks/${element['$key']}/presentations/`).then(async item => {
										if (item) {
											// console.log("size = ", item.length)
											for (let pos = 0; pos < item.length; pos++) {
												let elementItem = item[pos];
												if (elementItem.visible && Number(elementItem.inventory) > 0) {
													// console.log("presentationsItem = ", elementItem)
													element['price'] = elementItem.price
													element['presentation'] = elementItem.presentation
													// console.log("elementUpdate['price'] ", elementUpdate['price'], elementItem.price ,"elementUpdate['presentation'] ", elementUpdate['presentation'], elementItem.presentation )
													await this.bricks.push({ ...element, presentations_data: elementItem });
												}
											}
										}
									}).catch((error: any) => {
										// console.log("error = ", error)
									})
									// this.bricks.push(element);
								}
							}
						} else {
							if (element['countryCode'] == this.auth.countryCode) {
								//console.log(element);
								// console.log("response de algolia = ", element);
								this.bricks.push(element);
							}
						}
						// });
					}
					// console.log("this.bricks ===> ", this.bricks)
					this.loading = false;
				});
		} else {
			this.loading = false;
			this.searching = false;
			this.bricks = this.current_bricks;
		}
	}

	// trabajando agregar elementos
	items_cart: any = 0;

	counterButton(item, value) {
		// console.log("item", item, "value", value)
		if (value === 'add') {
			Number(item.quantity) > 0 ? item.quantity += 1 : item.quantity = 1;
			// console.log()
		} else if (value === 'remove') {
			Number(item.quantity) > 0 ? item.quantity -= 1 : item.quantity = 0;
		}
		if (Number(item.quantity) == 0) {
			delete item['isChange']
		}
		// console.log("item.quantity = ", item.quantity)
	}

	confirChangeBrick(brick) {
		// console.log("init confirChangeBrick", this.number_order, brick.presentations_data)

		let bricksUpdate = []
		if (brick.presentations_data && this.number_order) {
			this.api.getRef('orders').ref
				.where('number', '==', this.number_order)
				.get()
				.then(async snapshots => {
					if (snapshots.size > 0) {
						const { docs } = snapshots
						let { bricks } = docs[0].data();
						let keyOrder = docs[0].id
						// procedo a eliminar el brick que estoy reemplazando
						for (let index = 0; index < bricks.length; index++) {
							let element = bricks[index];
							if (element.brick_key == this.brick_delete.$key) {
								delete bricks[index]
								// break
							} else {
								bricksUpdate.push(bricks[index])
							}
						}
						// console.log("bricks in Orders = ", bricks)
						let obj = brick['presentations_data']
						let info: any
						await this.api.getDocument('bricks', brick.$key).then(data => {
							info = data;
						})


						obj['brick'] = info
						obj['brick_key'] = brick.$key
						obj['quantity'] = brick.quantity
						obj['subtotal'] = Number(brick.quantity) * Number(brick.price)
						obj['type'] = brick.type

						bricksUpdate.push(obj)

						this.api.updateDocument('orders', keyOrder, {
							bricks: bricksUpdate
						}).then(data => {
							// console.log("orden actualizada con nuevos productos")
						})
					}
				})
		}
	}

	confirmNewBrick(brick) {
		// console.log("confirmNewBrick(brick) = ", brick, "this.number_order = ", this.number_order)
		let bricksUpdate = []
		if (brick.presentations_data && this.number_order) {
			this.loader().then(loader => {
				loader.present().then(async () => {
					this.api.getRef('orders').ref
						.where('number', '==', this.number_order)
						.get()
						.then(async snapshots => {
							// console.log("snapshots.size = ", snapshots.size)
							if (snapshots.size > 0) {
								const { docs } = snapshots
								let { bricks, bricks_original } = docs[0].data();
								let keyOrder = docs[0].id

								// creando backup de lista de bricks al inicio de la orden
								if (bricks_original == undefined) {
									this.api.updateDocument('orders', keyOrder, {
										bricks_original: bricks
									})
								}

								// procedo a eliminar el brick que estoy reemplazando
								// if (this.brick_delete) {
								for (let index = 0; index < bricks.length; index++) {
									let element = bricks[index];
									if (this.brick_delete != undefined && element.brick_key == this.brick_delete.$key) {
									} else {
										bricksUpdate.push(bricks[index])
									}
								}
								// }
								// console.log("bricks in Orders = ", bricks)
								let obj = brick['presentations_data']
								let info: any
								await this.api.getDocument('bricks', brick.$key).then(data => {
									info = data;
								})

								obj['brick'] = info
								obj['brick_key'] = brick.$key
								obj['quantity'] = brick.quantity
								obj['subtotal'] = Number(brick.quantity) * Number(brick.price)
								obj['type'] = brick.type

								bricksUpdate.push(obj)
								// console.log("bricksUpdate.length ", bricksUpdate.length)

								this.api.updateDocument('orders', keyOrder, {
									bricks: bricksUpdate
								}).then(data => {
									// console.log("orden actualizada con nuevos productos")
									loader.dismiss();
									window.location.reload();
									// this.modalController.dismiss()
								})
							}
						})
				})
			})
		}
	}

	async loader() {
		return await this.loadingCtrl.create({
			spinner: 'lines-small',
			animated: true,
			mode: 'ios',
			translucent: false,
			cssClass: 'custom-class custom-loading',
		});
	}

}
