import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import { OrderComponent } from '../order/order.component';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChatComponent } from '../chat/chat.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { SwalService } from 'src/app/services/alert/swal.service';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {

    account: any;
    orders: any = [];
    last_order: any;
    total: any = 0;
    total_points: any = 0;
    free_deliveries: any = 0;
    points_earned: any = 0;
    businessForm: FormGroup;
    // estadisticas de ordenes 
    ordersCancel: any = 0
    orderDelivery: any = 0
    ordersTotalTip: any = 0
    ordersTotalDelivery: any = 0

    constructor(
        public api: ApiService,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public modalController: ModalController,
        private http: HttpClient,
        public formBuilder: FormBuilder,
        public auth: AuthService,
        private SwalService: SwalService
    ) {
        this.account = navParams.get('account');

        this.businessForm = this.formBuilder.group({
            taxpayer: [this.account.taxpayer, [Validators.required]],
            nrc: [this.account.nrc, [Validators.required]],
            nit: [this.account.nit, [Validators.required]],
            gyre: [this.account.gyre, [Validators.required]],
            address: [this.account.address, [Validators.required]],
            legal_entity: [this.account.legal_entity, [Validators.required]],
        });


        this.free_deliveries = this.account.free_deliveries;
        this.api.getRef('orders').ref
            .where('account', '==', this.account.$key)
            .orderBy('added_date', 'desc')
            .get()
            .then(snapshots => {
                snapshots.forEach(element => {
                    let order = element.data();
                    order.date = order.added_date.toDate();
                    order.$key = element.id;

                    if (order.status !== 'canceled') {
                        if (order.total) {
                            this.total += Number(order.total);
                        }
                        if (order.total_points) {
                            this.total_points += Number(order.total_points);
                        }
                        this.orderDelivery = this.orderDelivery + 1
                        if (order.points_earned) this.points_earned += Number(order.points_earned);
                        if (order.tip) this.ordersTotalTip = this.ordersTotalTip + Number(order.tip)
                        if (order.total_delivery) this.ordersTotalDelivery = this.ordersTotalDelivery + Number(order.total_delivery)

                    } else {
                        this.ordersCancel = this.ordersCancel + 1
                    }
                    // 
                    //console.log(`order = ${order.number} = `, order, `propina = ${order.tip} delivery = ${order.total_delivery}`)
                    // 

                    this.orders.push(order);
                });
                this.last_order = this.orders[0];
                //console.log("this.total = ", this.total)

            })

    }

    ngOnInit(

    ) {

    }


    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    async selectOrder(order) {
        const modal = await this.modalController.create({
            component: OrderComponent,
            cssClass: 'addModal',
            componentProps: {
                order: order
            }
        });
        return await modal.present();
    }

    async selectChat(chat) {
        const modal = await this.modalController.create({
            component: ChatComponent,
            cssClass: 'addModal',
            componentProps: {
                conversation_key: chat.$key
            }
        });
        return await modal.present();
    }

    changeStatus(order, status) {
        this.SwalService.fire({
            title: 'Esta seguro que deseas cambiar el estado?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
            if (result.value) {
                this.api.updateDocument(`orders`, order.$key, { status: status }).then(data => {
                    order.status = status;
                    let message;
                    if (status == 'making') {
                        message = `Tu orden #${order.number} se está preparando 🔥`
                    } else if (status == 'way') {
                        message = `Tu orden #${order.number} ya va de camino 🚚`
                    } else if (status == 'delivered') {
                        message = `Tu orden #${order.number} ha sido entregada 🏡`
                    }

                    this.api.getAllDocuments(`accounts/${order.account.$key}/push_keys`).then(keys => {
                        if (keys) {
                            let push_keys = [];
                            keys.forEach(element => {
                                push_keys.push(element.push_key);
                            });

                            this.api.sendPush(`Actualización de estado`, message, push_keys);
                        }
                    })

                    this.SwalService.fire(
                        'Estado actualizado!',
                        'Tu estado ha sido actualizado correctamente',
                        'success'
                    )
                });
            }
        })
    }

    capture(order) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer prv_prod_PWw13Ai1yGYJuTF6BQJ99nG8w9MkTHAw'
            })
        };

        this.loader().then(loader => {
            loader.present().then(() => {
                this.SwalService.fire({
                    title: 'Esta seguro que deseas Aceptar la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {

                    if (result.value) {
                        let getData = `orderNo=${order.number}&amount=${order.total}&type=1`;
                        let id_transaction = order.transaction_id;
                        let url;
                        if (this.auth.countryCode == 'SV') {
                            url = `https://payments.barlleno.app/transactionModification.php?${getData}`;
                        } else if (this.auth.countryCode == 'GT') {
                            url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
                        } else if (this.auth.countryCode == 'CO') {
                            url = `https://production.wompi.co/v1/transactions/?${id_transaction}`;
                        }

                        this.http.get(url)
                            .subscribe((response) => {
                                if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                                    this.api.updateDocument('orders', order.$key, {
                                        'transaction_status': 'captured',
                                        'status': 'making'
                                    }).then(data => {
                                        order.transaction_status = 'captured';
                                        this.SwalService.fire(
                                            'Transacción Capturada',
                                            'Captura de fondos realizar con exito',
                                            'success'
                                        )
                                    })
                                } else {
                                    this.SwalService.fire(
                                        'Error!',
                                        'No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno',
                                        'error'
                                    )
                                }
                                loader.dismiss();
                            }, err => {
                                loader.dismiss();
                            });
                    } else {
                        loader.dismiss();
                    }
                });
            })
        })
    }

    revert(order) {

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer prv_prod_PWw13Ai1yGYJuTF6BQJ99nG8w9MkTHAw'
            })
        };

        this.loader().then(loader => {
            loader.present().then(() => {
                this.SwalService.fire({
                    title: 'Esta seguro que deseas Revertir la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {
                    if (result.value) {
                        let getData = `orderNo=${order.number}&amount=${order.total}&type=3`;
                        let url;
                        let id_transaction = this.orders.transaction_id;
                        if (this.auth.countryCode == 'CO') {
                            url = `https://production.wompi.co/v1/transactions/?${id_transaction}`;
                        } else if (this.auth.countryCode == 'SV') {
                            url = `https://payments.barlleno.app/transactionModification.php?${getData}`;
                        } else if (this.auth.countryCode == 'GT') {
                            url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
                        }

                        this.http.get(url)
                            .subscribe((response) => {
                                if (response['TransactionModificationResult']['ResponseCode'] == 1 || response['data']['status'] == 'VOIDED') {
                                    this.api.updateDocument('orders', order.$key, {
                                        'transaction_status': 'Reverted',
                                        'status': 'canceled'

                                    }).then(data => {
                                        order.transaction_status = 'reverted';
                                        this.SwalService.fire(
                                            'Transacción Revertida',
                                            'Reversión de fondos realizar con exito',
                                            'success'
                                        )
                                    })
                                } else {
                                    this.SwalService.fire(
                                        'Error!',
                                        'No se pudo completar, comunicate con el equipo de Barlleno',
                                        'error'
                                    )
                                }
                                loader.dismiss();
                            }, err => {
                                loader.dismiss();
                            });
                    }
                    else {
                        loader.dismiss();
                    }
                })
            })
        })
    }

    isActive(status, order_status) {

        if (order_status == 'pending') {
            if (status == 'pending') {
                return 'true';
            }
        }

        if (order_status == 'making') {
            if (status == 'pending' || status == 'making') {
                return 'true';
            }
        }

        if (order_status == 'way') {
            if (status == 'pending' || status == 'making' || status == 'way') {
                return 'true';
            }
        }

        if (order_status == 'delivered') {
            if (status == 'pending' || status == 'making' || status == 'way' || status == 'delivered') {
                return 'true';
            }
        }
    }

    cancelOrder(order) {
        this.SwalService.fire({
            title: `Esta seguro que deseas CANCELAR la orden ${order.number}?`,
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
            if (result.value) {
                //console.log(order);
                this.api.cancelOrder(order).then(data => {
                    order.status = 'canceled';
                })
            }

        })
    }

    updateDeliveries() {
        this.api.updateDocument('accounts', this.account.$key, {
            free_deliveries: Number(this.free_deliveries)
        }).then(data => {
            this.SwalService.fire(
                'Actualización correcta',
                'La actualización de los free deliveries se ha realizado con exito.',
                'success'
            )
        })
    }

    updateBusinessAccount() {
        this.api.updateDocument('accounts', this.account.$key, {
            taxpayer: this.businessForm.value.taxpayer,
            nrc: this.businessForm.value.nrc,
            nit: this.businessForm.value.nit,
            gyre: this.businessForm.value.gyre,
            address: this.businessForm.value.address,
            legal_entity: this.businessForm.value.legal_entity,
        }).then(data => {
            if (this.businessForm.value.legal_entity) {
                this.SwalService.fire(
                    'Cuenta de negocios activada',
                    'Esta cuenta ha sido actualizada.',
                    'success'
                )
            } else {
                this.SwalService.fire(
                    'Cuenta de negocios desactivada',
                    'Esta cuenta ha sido actualizada.',
                    'success'
                )
            }
        })
    }

    exportAccount() {
        let data = []
        // informacion de la cuenta this.account
        //console.log("this.account = ", this.account)
        //console.log("this.orders = ", this.orders)
        // return

        this.orders.forEach(element => {
            data.push({
                "Dispositivo": `${element.systemOs != undefined ? element.systemOs : ''}`,
                "Orden #": element.number,
                "Cliente": `${this.account.name} ${this.account.lastname}`,
                "Sexo": `${this.account.gender != undefined ? this.account.gender : ''}`,
                "Edad": `${this.account.showAge != undefined ? this.account.showAge : ''}`,
                "Correo Electronico": `${this.account.email != undefined ? this.account.email : ''}`,
                "Teléfono": `${this.account.phone != undefined ? this.account.phone : ''}`,
                "Fecha": `${element.date != undefined ? element.date : ''}`,
                "Estado": `${element.status != undefined ? element.status : ''}`,
                "Pago": `${element.total != undefined ? element.total : ''}`,
                "Pago Delivery": `${element.total_delivery != undefined ? element.total_delivery : ''}`,
                "Pago Tip": `${element.tip != undefined ? element.tip : ''}`,
                "Tipo de pago": `${element.payment.type != undefined ? element.payment.type : ''}`,
                "total_points": `${element.total_points != undefined ? element.total_points : 'NA'}`,
                "lat": `${element.address.lat != undefined ? element.address.lat : ''}`,
                "lng": `${element.address.lng != undefined ? element.address.lng : ''}`,
                "Direccion": `${element.address.location != undefined ? element.address.location : ''}`,
            })
        });

        data.push(new Array(''))
        let titleEstadisticas = new Array(`Estadisticas de cuenta ${this.account.email} - ${this.account.name} ${this.account.lastname}`);
        data.push(titleEstadisticas)
        let headerEstadisticas = new Array(
            `Ordenes Finalizadas`,
            `Ordenes Canceladas`,
            `Total consumido en productos`,
            `Total consumido delivery`,
            `Total propinas`,
            `Puntos acumulados`,
            `Total puntos consumidos`,
            `Total puntos actuales`,
            `Free Deliveries`,
            `Ultima compra`,
        )
        let lastOrderDate = 'ND'
        if (this.last_order.date) {
            lastOrderDate = this.last_order.date
        }
        data.push(headerEstadisticas)
        let contentEstadisticas = new Array(
            this.orderDelivery,
            this.ordersCancel,
            this.total - this.ordersTotalTip - this.ordersTotalDelivery,
            this.ordersTotalDelivery,
            this.ordersTotalTip,
            this.points_earned,
            this.total_points,
            this.points_earned - this.total_points,
            this.free_deliveries,
            lastOrderDate,
        )
        data.push(contentEstadisticas)

        var options = {
            title: `Reporte Cuenta de Usuario ${this.account.email} Barlleno`,
            headers: ["Dispositivo", "Orden #", "Cliente", "Sexo", "Edad", "Correo Electronico", "Teléfono", "Fecha", "Estado", "Pago", "Pago Delivery", "Pago Tip", "Tipo de pago", "total points gastados","lat", "lng", "Direccion"]
        };

        new Angular5Csv(data, `Reporte Producto Barlleno ${this.auth.countryCode} ${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`, options);

    }

    activeTradeEntity(account, event) {
        //console.log("account.$key = ", account.$key, "this.account.$key = ", this.account.$key, "account.trade_entity_active = ", account.trade_entity_active)
        let trade_entity_active = account.trade_entity_active
        if (account.trade_entity_active == false) {
            trade_entity_active = true
        } else {
            trade_entity_active = false
        }

        // lista de entidades comerciales registradas en barlleno DB
        let trade_entity = {}
        trade_entity['cantina_entity'] = false
        trade_entity['la15_entity'] = false
        trade_entity['superb_entity'] = false

        let message = `La cuenta ${this.account.email} ha sido actualizada a tipo 'Entidad Comercial'.`
        if (trade_entity_active != true) {
            message = `La cuenta ${this.account.email} ha sido actualizada a tipo 'Barlleno Normal'.`
        }
        
        this.api.updateDocument('accounts', this.account.$key, { trade_entity_active: trade_entity_active, trade_entity }).then(data => {
            this.SwalService.fire(
                'EXITO',
                message,
                'success'
            )
        })
    }

}
