import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SwalService } from 'src/app/services/alert/swal.service';

@Component({
	selector: 'app-add-blog',
	templateUrl: './add-blog.component.html',
	styleUrls: ['./add-blog.component.scss'],
})
export class AddBlogComponent implements OnInit {
	brickForm: FormGroup;
	brick_key: any;
	brick: any;
	brick_image: any = false;
	presentationName: any;
	presentationInventario: any;
	presentations: any = [];
	category: any;
	type: any = 'brick';
	imageBrick: any;

	constructor(
		public api: ApiService,
		public formBuilder: FormBuilder,
		public loadingCtrl: LoadingController,
		public navParams: NavParams,
		public modalController: ModalController,
		public auth: AuthService,
        private SwalService: SwalService
	) {
		let category = navParams.get('category');


		if (navParams.get('type')) {
			this.type = navParams.get('type');
		}
		
		if (navParams.get('blog')) {
			this.brick = navParams.get('blog');

		}

// this.brick != undefined ? this.brick.name : this.nameBrick
		let title = navParams.get('name');
		this.brickForm = this.formBuilder.group({
			title: [this.brick != undefined ? this.brick.title : title, [Validators.required]],
			description: [this.brick != undefined ? this.brick.description : '', [Validators.required]],
			visible: [true, [Validators.required]],
			category: [category.$key, [Validators.required]],
			type: [this.type, [Validators.required]],
			image_temp: [this.brick != undefined ? this.brick.image.downloadURL :'', [Validators.required]],
			subTitle: [this.brick != undefined ? this.brick.subTitle : '', [Validators.required]],
			countryCode: [this.auth.countryCode],
		});
	}

	ngOnInit() { }

	
    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    uploadedImages(file) {
        this.brick_image = {
            name: file.file.name,
            src: file.src
        };
    }

    removeImages() {
        this.brick_image = false;
    }

    addBrick() {
        if (this.brick != undefined && this.brick.hasOwnProperty('$key')) {

            this.loader().then(loader => {
                loader.present().then(() => {
					//console.log("this.brickForm.value ", this.brickForm.value)
					this.brick['title'] = this.brickForm.value.title
					this.brick['subTitle'] = this.brickForm.value.subTitle
					this.brick['description'] = this.brickForm.value.description

                    this.api.updateBlog(this.brick, this.brick_image.src).then(data => {
                        loader.dismiss();

                        this.SwalService.fire('¡Listo!', 'Tu Blog ha sido actualizado', 'success');
                        this.modalController.dismiss();
                    });
                })
            })
        }else{
			this.loader().then(loader => {
                loader.present().then(() => {
					//console.log("this.brickForm.value ", this.brickForm.value)
					
                    this.api.addBlog(this.brickForm.value, this.brick_image.src).then(data => {
                        loader.dismiss();

                        this.SwalService.fire('¡Listo!', 'Tu Blog ha sido agregado', 'success');
                        this.modalController.dismiss();
                    });
                })
            })
		}
    }


    changeMain(presentation, $event) {
        this.presentations.forEach(element => {
            element.main = false;
        });
        presentation.main = true;

    }

    addPresentation() {
        this.presentations.push({
            presentation: this.presentationName,
            inventory: "0",
            price: 0,
        });
        this.presentationName = '';
    }

    removePresentation(presentation) {
        for (let index = 0; index < this.presentations.length; index++) {
            const element = this.presentations[index];
            if (element.presentation == presentation.presentation) {
                this.presentations.splice(index, 1)
            }
        }

    }


}
