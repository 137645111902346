import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'monitor',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/monitor/monitor.module').then(m => m.MonitorPageModule)
    },
    {
        path: 'bricks',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/bricks/bricks.module').then(m => m.BricksPageModule)
    },
    {
        path: 'bricks/:brick_key',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/brick/brick.module').then(m => m.BrickPageModule)
    },
    {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
    },
    {
        path: 'chats',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/chats/chats.module').then(m => m.ChatsPageModule)
    },
    {
        path: 'chats-pendientes',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/chats-pendientes/chats.module').then(m => m.ChatsPageModule)
    },
    {
        path: 'chat/:conversation_key',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
    },
    {
        path: 'section/:section',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/section/section.module').then(m => m.SectionPageModule)
    },
    {
        path: 'category/:category_key',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule),
    },
    {
        path: 'category/:category_key/:category_type',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'explore-section',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/explore-section/explore-section.module').then(m => m.ExploreSectionPageModule)
    },
    {
        path: 'explore-category/:category_key',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/explore-category/explore-category.module').then(m => m.ExploreCategoryPageModule)
    },
    {
        path: 'verified-requests',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/verified-requests/verified-requests.module').then(m => m.VerifiedRequestsPageModule)
    },
    {
        path: 'verified',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/verified/verified.module').then(m => m.VerifiedPageModule)
    },
    {
        path: 'push',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/push/push.module').then(m => m.PushPageModule)
    },
    {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsPageModule)
    },
    {
        path: 'accounts',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/accounts/accounts.module').then(m => m.AccountsPageModule)
    },
    {
        path: 'coupons',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/coupons/coupons.module').then(m => m.CouponsPageModule)
    },
    {
        path: 'evaluaciones',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/evaluaciones/evaluaciones.module').then(m => m.EvaluacionesPageModule)
    },
    {
        path: 'business-accounts',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/business-accounts/business-accounts.module').then(m => m.BusinessAccountsPageModule)
    },
    {
        path: 'business-accounts-validate',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/business-accounts-validate/business-accounts-validate.module').then(m => m.BusinessAccountsValidatePageModule)
    },
    {
        path: 'beerday',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/beerday/beerday.module').then(m => m.BeerdayPageModule)
    },
    {
        path: 'delivery',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/delivery/delivery.module').then(m => m.DeliveryPageModule)
    },
    {
        path: 'search-brick',
        loadChildren: () => import('./pages/search-brick/search-brick.module').then(m => m.SearchBrickPageModule)
    },
    {
        path: 'explore-cocktail-day',
        loadChildren: () => import('./pages/explore-cocktail-day/explore-cocktail-day.module').then(m => m.ExploreCocktailDayPageModule)
    },
    {
        path: 'explore-cocktail-finder',
        loadChildren: () => import('./pages/explore-cocktail-finder/explore-cocktail-finder.module').then(m => m.ExploreCocktailFinderPageModule)
    },
    {
        path: 'explore-bartenders/:category_key',
        loadChildren: () => import('./pages/explore-bartenders/explore-bartenders.module').then(m => m.ExploreBartendersPageModule)
    },
    {
        path: 'support',
        loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule)
    },
    {
        path: 'events',
        loadChildren: () => import('./pages/events/events.module').then(m => m.EventsPageModule)
    },
    {
        path: 'event-details/:category_key',
        loadChildren: () => import('./pages/event-details/event-details.module').then(m => m.EventDetailsPageModule)
    },
    {
        path: 'suppliers',
        loadChildren: () => import('./pages/suppliers/suppliers.module').then(m => m.SuppliersPageModule)
    },
    {
        path: 'import-csv',
        loadChildren: () => import('./pages/import-csv/import-csv.module').then(m => m.ImportCsvPageModule)
    },
    {
        path: 'import-csv-sap',
        loadChildren: () => import('./pages/import-csv-sap/import-csv-sap.module').then(m => m.ImportCsvSapPageModule)
    },
    {
        path: 'verify-payment',
        loadChildren: () => import('./pages/verify-payment/verify-payment.module').then(m => m.VerifyPaymentPageModule)
    },
    {
        path: 'monitor-requests-orders',
        loadChildren: () => import('./pages/monitor-requests-orders/monitor-requests-orders.module').then(m => m.MonitorRequestsOrdersPageModule)
    },
    {
        path: 'monitor-v2',
        loadChildren: () => import('./pages/monitor-v2/monitor-v2.module').then(m => m.MonitorV2PageModule)
    },
    {
        path: 'monitor-v3',
        loadChildren: () => import('./pages/monitor-v3/monitor-v3.module').then(m => m.MonitorV3PageModule)
    },
    {
        path: 'reports-v2',
        loadChildren: () => import('./pages/reports-v2/reports-v2.module').then(m => m.ReportsV2PageModule)
    },
    {
        path: 'reports-event',
        loadChildren: () => import('./pages/reports-event/reports-event.module').then(m => m.ReportsEventPageModule)
    },
    {
        path: 'permission-denied',
        canActivate: [AuthGuard],

        loadChildren: () => import('./pages/permission-denied/permission-denied.module').then( m => m.PermissionDeniedPageModule)
    }

    
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
