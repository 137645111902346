import { UploadService } from '../../services/uploads/upload.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { of } from 'rxjs';
import { AddReferenceComponent } from '../add-reference/add-reference.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { SwalService } from 'src/app/services/alert/swal.service';
import { present } from '@ionic/core/dist/types/utils/overlays';
import { ClipboardService } from 'ngx-clipboard'

@Component({
    selector: 'app-update-brick',
    templateUrl: './update-brick.component.html',
    styleUrls: ['./update-brick.component.scss'],
})
export class UpdateBrickComponent implements OnInit {

    brickForm: FormGroup;
    brick_key: any;
    brick: any;
    brick_image: any = false;
    presentationName: any;
    on_presentationName: any;
    presentations: any = [];
    on_presentations: any = [];
    category: any;
    type: any = 'brick';
    recommended: any = [];
    referenceKits: any = [];
    isToggleBtnChecked: any = ''
    creation_date: any
    oldVisible: any
    // supplier dinamic DB
    supplier: any
    nivel: any

    listImage: string[] = [];
    listImageFile: File[] = [];
    newListReference: any = [];
    listReference: any = [];
    listTempReference: any = [];
    listKeywords: string[] = [];

    mainImage: string;
    mainImageFile: File;

    wineisActive: boolean = false;

    tinyConfs: any = {
        height: 250,
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount image textpattern noneditable help charmap  emoticons',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        importcss_append: true,
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: 'sliding',
        contextmenu: "link table",
        templates: [
            { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
        ],
    }

    // config options select suppliers
    optionsSuppliers = [];
    unit_system = [];
    presentation_unidades: any
    category_key: any;


    // trade entity (superB)
    superb_PresentationName: any;
    superb_on_presentationName: any;
    // collections superb
    superbPresentations: any = [];
    superb_on_presentations: any = [];

    clipboard: any = ''
    isCopied1: boolean;

    constructor(
        public api: ApiService,
        private uploadService: UploadService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public auth: AuthService,
        private http: HttpClient,
        public modalController: ModalController,
        private SwalService: SwalService,
        private _clipboardService: ClipboardService
    ) {
        this.brick = navParams.get('brick');
        //console.log("typeof this.brick", typeof this.brick)
        this.category_key = navParams.get('category_key');

        if (this.brick) {
            this.clipboard = this.brick.$key
            //console.log("this.oldVisible", this.oldVisible, "this.brick.visible", this.brick.visible)
            this.oldVisible = this.brick.visible
            //console.log("this.oldVisible", this.oldVisible, "this.brick.visible", this.brick.visible)
            // //console.log("this.brick = ", this.brick)
            this.supplier = this.brick.supplier
            this.nivel = this.brick.nivel
            if (this.brick.referenceKits) {
                this.referenceKits = this.brick.referenceKits
            }
        }

        if (navParams.get('type')) {
            this.type = navParams.get('type');
        }

        // original - antes de integrar las nuevas propiedades del formulario
        // this.brickForm = this.formBuilder.group({
        //     name: [this.brick.name, [Validators.required]],
        //     description: [this.brick.description, [Validators.required]],
        //     visible: [this.brick.visible, [Validators.required]],
        //     type: ['brick', [Validators.required]],
        //     on_trade: [this.brick.on_trade, [Validators.required]],
        //     url_link: [this.brick.url_link],
        //     supplier: [this.brick.supplier],
        //     bar_code: [this.brick.bar_code],
        //     position: [this.brick.position],
        //     free_delivery: [this.brick.free_delivery],
        // });
        this.brickForm = this.formBuilder.group({
            brand: [this.brick.brand, [Validators.required]],
            name: [this.brick.name, [Validators.required]],
            line: [this.brick.line, [Validators.required]],
            matriz: [this.brick.matriz, [Validators.required]],
            description: [this.brick.description],
            visible: [this.brick.visible, [Validators.required]],
            type: [this.brick.type, [Validators.required]],
            on_trade: [this.brick.on_trade, [Validators.required]],
            url_link: [this.brick.url_link],
            supplier: [this.brick.supplier],
            nivel: [this.brick.nivel],
            bar_code: [this.brick.bar_code],
            system_code: [this.brick.system_code],
            position: [this.brick.position],
            free_delivery: [this.brick.free_delivery],
            // volume: [this.brick.volume ? this.brick.volume : { value: '', disabled: true }],
            // boolVolume: [this.brick.boolVolume ? this.brick.boolVolume : false],
            alcohol: [this.brick.alcohol ? this.brick.alcohol : { value: '', disabled: true }],
            boolAlcohol: [this.brick.boolAlcohol ? this.brick.boolAlcohol : false],
            detalles: this.formBuilder.group({
                region: [{ value: '', disabled: true }],
                temperatura: [{ value: '', disabled: true }],
                maridaje: [{ value: '', disabled: true }],
                cristaleria: [{ value: '', disabled: true }],
                notasCatacion: [{ value: '', disabled: true }],
                boolRegion: [false],
                boolTemperatura: [false],
                boolMaridaje: [false],
                boolCristaleria: [false],
                boolNotasCata: [false],
            }),
            activarVino: [false],

        });

        this.loadImagesCarrousel(this.brick);
        this.loadDataWine(this.brick)
        this.loadDetails(this.brick)


        if (this.type == 'brick') {
            this.api.getRef(`bricks/${this.brick.$key}/presentations`).ref
                .onSnapshot(snapshots => {
                    snapshots.docChanges().forEach(element => {
                        if (element.type == 'added') {
                            let presentation = element.doc.data();
                            presentation.$key = element.doc.id;
                            this.presentations.push(presentation);
                        }
                    });
                })

            this.api.getRef(`bricks/${this.brick.$key}/on_presentations`).ref
                .onSnapshot(snapshots => {
                    snapshots.docChanges().forEach(element => {
                        if (element.type == 'added') {
                            let presentation = element.doc.data();
                            presentation.$key = element.doc.id;
                            this.on_presentations.push(presentation);
                        }
                    });
                })

            // inicio superb escuchador de presentaciones
            this.api.getRef(`bricks/${this.brick.$key}/superb_presentations`).ref
                .onSnapshot(snapshots => {
                    snapshots.docChanges().forEach(element => {
                        if (element.type == 'added') {
                            let presentation = element.doc.data();
                            presentation.$key = element.doc.id;
                            this.superbPresentations.push(presentation);
                        }
                    });
                })

            this.api.getRef(`bricks/${this.brick.$key}/superb_on_presentations`).ref
                .onSnapshot(snapshots => {
                    snapshots.docChanges().forEach(element => {
                        if (element.type == 'added') {
                            let presentation = element.doc.data();
                            presentation.$key = element.doc.id;
                            this.superb_on_presentations.push(presentation);
                        }
                    });
                })
            // fin superb escuchador de presentaciones
        }
        this.getReferences();
        this.getReferencesKits();
        this.optionsSuppliers.unshift();
        // recuperando informacion de los Proveedores Barlleno
        this.api.getAllDocuments(`suppliers`).then(data => {
            if (data) {
                data.forEach(element => {
                    if (element['countryCode'] == this.auth.countryCode && element['active'] == true) {
                        let arr = []
                        arr['id'] = element.$key
                        arr['name'] = element.name
                        this.optionsSuppliers.push(arr);
                    }
                });
            }
        });
        this.getPresentationsBrick(this.presentations)
        this.getUnitSystem()
    }

    ngOnInit() { }

    getPresentationsBrick(presentations) {
        return new Promise(async (resolve, reject) => {
            //console.log("presentations = ", presentations)
        })
    }

    getUnitSystem() {
        this.api.getRef('catalogue').ref
            .where('type', '==', 'unit_system')
            .orderBy('name', 'asc')
            .get()
            .then(data => {
                data.forEach(element => {
                    if (element) {
                        let unit = element.data();
                        unit.$key = element.id;
                        this.unit_system.push(unit);
                    }
                });
            })
        //console.log("this.unit_system = ",this.unit_system)
    }

    getReferences() {
        this.recommended = [];
        this.api.getRef(`recommended/`).ref
            .where('brick', '==', this.brick.$key)
            .get()
            .then(snapshots => {
                snapshots.forEach(element => {

                    let recommended = element.data();
                    recommended.$key = element.id;
                    //console.log(recommended);


                    this.api.getDocument('bricks', element.data().reference).then(data => {
                        recommended.brick = data;
                        this.recommended.push(recommended);
                    }, err => {
                        this.api.deleteDocument('bricks', recommended.$key)
                    });

                });
            })
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    uploadedImages(file) {
        this.brick_image = {
            name: file.file.name,
            src: file.src
        };
    }

    removeImages() {
        this.brick_image = false;
    }

    addBrick() {
        this.loader().then(loader => {
            loader.present().then(async () => {
                // //console.log("addBrick() this.brick = ", this.brick)
                // validaciones de seccion detalle del brick
                if (this.brickForm.get('on_trade').value == undefined) {
                    this.brickForm.get('on_trade').setValue(false);
                }

                if (this.reviewValidations() == false) {
                    // //console.log(this.brickForm.controls)
                    loader.dismiss();
                    return;
                }

                let obj = []
                for (let index = 0; index < this.referenceKits.length; index++) {
                    const element = this.referenceKits[index];
                    //console.log("item in referenceKits = ", element)
                    //console.log("element.brick.$key = ", this.brick.$key, "element.reference = ", element.reference, "element.visible = ", element.visible)
                    obj.push({ brick: this.brick.$key, reference: element.reference, visible: element.visible })
                }
                //console.log("obj => ", obj)
                this.brick.referenceKits = obj

                // console.log("this.brickForm.value.name", this.brickForm.value.name, "this.brickForm.value", this.brickForm.value)
                this.brick = Object.assign(this.brick, this.brickForm.value)
                this.brick.brand = this.brickForm.value.brand;
                this.brick.name = this.brickForm.value.name;
                this.brick.line = this.brickForm.value.line;
                this.brick.description = this.brickForm.value.description;
                this.brick.visible = this.brickForm.value.visible;
                this.brick.bar_code = this.brickForm.value.bar_code;
                // this.brick.supplier = this.brickForm.value.supplier;
                this.brick.supplier = this.supplier;
                this.brick.free_delivery = this.brickForm.value.free_delivery;
                this.brick.on_trade = this.brickForm.value.on_trade;
                this.brick.url_link = this.brickForm.value.url_link;

                // nuevos campos
                this.brick.nivel = this.nivel;
                this.brick.matriz = this.brickForm.value.matriz;
                this.brick.system_code = this.brickForm.value.system_code;

                // agregando nuevos UX detalle del brick en objeto a guardar en la base
                // this.brick.boolAlcohol = this.brickForm.value.boolAlcohol;
                // this.brick.alcohol = this.brickForm.value.alcohol;
                // this.brick.boolVolume = this.brickForm.value.boolVolume;
                // this.brick.volume = this.brickForm.value.volume;
                // this.brick.detalles.boolRegion = this.brickForm.value.;
                // this.brick. = this.brickForm.value.;
                // //console.log("this.bricks", this.brick)
                Object.keys(this.brick).forEach(key => this.brick[key] === undefined ? delete this.brick[key] : {});
                // //console.log("this.bricks delete undefined", this.brick)

                // return

                // validaciones de datos
                if (this.brick.brand == '' || this.brick.brand == undefined) {
                    this.mensajeAlerta('error', 'Favor completar Nombre de Marca');
                    loader.dismiss();
                    return;
                }
                if (this.brick.name == '' || this.brick.name == undefined) {
                    this.mensajeAlerta('error', 'Favor completar Nombre de Producto');
                    loader.dismiss();
                    return;
                }
                if (this.brick.line == '' || this.brick.line == undefined) {
                    this.mensajeAlerta('error', 'Favor completar Linea de Producto');
                    loader.dismiss();
                    return;
                }
                if (this.brick.image == '' || this.brick.image == undefined) {
                    this.mensajeAlerta('error', 'Favor completar Imagen de Producto');
                    loader.dismiss();
                    return;
                }
                if (this.brick.description == '' || this.brick.description == undefined || this.brick.description.length <= 3) {
                    this.mensajeAlerta('error', 'Favor completar Descripción del Producto');
                    loader.dismiss();
                    return;
                }
                if (this.brick.matriz == '' || this.brick.matriz == undefined || this.brick.matriz.length <= 4) {
                    this.mensajeAlerta('error', 'Favor completar Matriz del Producto');
                    loader.dismiss();
                    return;
                }
                if (!(this.presentations.length > 0) && !(this.on_presentations.length > 0)) {
                    this.mensajeAlerta('error', 'Favor completar Presentaciones del Producto');
                    loader.dismiss();
                    return;
                } else {
                    // para barlleno normal
                    const flagBoolean = await this.presentations.map(item => {
                        let price = item.price
                        if (price) return (price.toString().indexOf(',') > -1)
                        else return false
                    })
                    //console.log("flagBoolean ==>", flagBoolean)
                    if (flagBoolean.includes(true)) {
                        this.mensajeAlerta('error', 'Precio no valido, reemplazar (,) por (.)');
                        loader.dismiss();
                        return
                    }

                    // para barlleno ON
                    const flagBooleanON = await this.on_presentations.map(item => {
                        let price = item.price
                        if (price) return (price.toString().indexOf(',') > -1)
                        else return false
                    })
                    //console.log("flagBoolean ==>", flagBooleanON)
                    if (flagBooleanON.includes(true)) {
                        this.mensajeAlerta('error', 'Precio no valido, reemplazar (,) por (.)');
                        loader.dismiss();
                        return
                    }

                    // validacion para seleccion de presentacion principal
                    // para barlleno normal

                    const flagBooleanMain = await this.presentations.map(item => {
                        if (item.main == true) return item.main
                        else return false
                    })

                    //console.log("flagBooleanMain ==>", flagBooleanMain)
                    if (!flagBooleanMain.includes(true)) {
                        this.mensajeAlerta('error', 'Favor seleccionar Presentación Principal');
                        loader.dismiss();
                        return
                    }
                }
                // fin validaciones de datos

                if (!this.brickForm.get('boolAlcohol').value) {
                    this.brick.alcohol = '';
                }

                if (!this.brick.position) {
                    this.brick.position = 0;
                }
                // cargando imagenes en base Storage
                if (!this.brick.imagen_carrusel) {
                    // let downloadURL =  {0:'',1:'',2:''}
                    this.brick['imagen_carrusel'] = { 'downloadURL': [this.brick.image.downloadURL, '', ''] }
                    // let downloadURL =  {0:'',1:'',2:''}
                    // this.brick['imagen_carrusel'] = [downloadURL]
                }
                // //console.log("this.brick['imagen_carrusel']", this.brick['imagen_carrusel'])
                // console.log("this.listImageFile = ", this.listImageFile)
                if (this.listImageFile[0]) {
                    await this.uploadService.uploadImagesByPosition(this.category_key, this.listImageFile[0]).then(
                        async response => {
                            this.brick.imagen_carrusel['downloadURL'][0] = await this.brick.image.downloadURL;
                        }
                    );
                }

                if (this.listImageFile[1]) {
                    await this.uploadService.uploadImagesByPosition(this.category_key, this.listImageFile[1]).then(
                        async response => {
                            this.brick.imagen_carrusel['downloadURL'][1] = await response;
                        }
                    );
                }

                if (this.listImageFile[2]) {
                    await this.uploadService.uploadImagesByPosition(this.category_key, this.listImageFile[2]).then(
                        async response => {
                            this.brick.imagen_carrusel['downloadURL'][2] = await response;
                        }
                    );
                }
                //console.log("this.brick", this.brick, "this.wineisActive = ", this.wineisActive)
                if (this.wineisActive == false && this.brick.hasOwnProperty('vino')) {
                    this.brick.vino = null
                }

                if (this.brick.detalles) { //hasOwnProperty
                    // console.log("this.brick.detalles = ", this.brick.detalles)
                    if (this.brick.detalles.hasOwnProperty('boolRegion') && this.brick.detalles.boolRegion == true) {
                        if (this.brick.detalles.hasOwnProperty('region') && !(this.brick.detalles['region'].length > 0)) {
                            this.brick.detalles.boolRegion = false
                        }
                    }
                    if (this.brick.detalles.hasOwnProperty('boolTemperatura') && this.brick.detalles.boolTemperatura == true) {
                        if (this.brick.detalles.hasOwnProperty('temperatura') && !(this.brick.detalles['temperatura'] > 0)) {
                            this.brick.detalles.boolTemperatura = false
                        }
                    }
                    if (this.brick.detalles.hasOwnProperty('boolMaridaje') && this.brick.detalles.boolMaridaje == true) {
                        if (this.brick.detalles.hasOwnProperty('maridaje') && !(this.brick.detalles['maridaje'].length > 0)) {
                            this.brick.detalles.boolMaridaje = false
                        }
                    }
                    if (this.brick.detalles.hasOwnProperty('boolCristaleria') && this.brick.detalles.boolCristaleria == true) {
                        if (this.brick.detalles.hasOwnProperty('cristaleria') && !(this.brick.detalles['cristaleria'].length > 0)) {
                            this.brick.detalles.boolCristaleria = false
                        }
                    }
                    if (this.brick.detalles.hasOwnProperty('boolNotasCata') && this.brick.detalles.boolNotasCata == true) {
                        if (this.brick.detalles.hasOwnProperty('notasCatacion') && !(this.brick.detalles['notasCatacion'].length > 0)) {
                            this.brick.detalles.boolNotasCata = false
                        }
                    }
                }

                // await new Promise(r => setTimeout(r, 500));

                await this.api.getRef('bricks').ref
                    .where('brick', '==', this.brick.$key)
                    .where('type', '==', 'reference')
                    .get()
                    .then(data => {
                        data.forEach(element => {
                            this.api.updateDocument('bricks', element.id, {
                                visible: this.brick.visible
                            })
                        });
                    })
                if (this.brick.reference != undefined) {
                    // //console.log("es una referencia el brick")
                } else {
                    // //console.log("no es referencia el brick")
                    this.brick.position = this.brickForm.value.position;
                }
                // el producto se coloco en invisible
                // console.log("this.brick = ", this.brick ,"this.brick.imagen_carrusel['downloadURL'] = ", this.brick.imagen_carrusel['downloadURL'], typeof this.brick.imagen_carrusel['downloadURL'])

                if (this.brick.imagen_carrusel['downloadURL']) {
                    let imagen_carrusel = { 'downloadURL': [] }
                    for (let i = 0; i < this.brick.imagen_carrusel['downloadURL'].length; i++) {
                        if (this.brick.imagen_carrusel['downloadURL'][i] == '') {
                          // console.log("this.brick.imagen_carrusel['downloadURL'][i] = ", this.brick.imagen_carrusel['downloadURL'][i])
                            delete this.brick.imagen_carrusel['downloadURL'][i]
                        } else {
                            imagen_carrusel['downloadURL'].push(this.brick.imagen_carrusel['downloadURL'][i])
                        }
                    }
                    this.brick.imagen_carrusel['downloadURL'] = imagen_carrusel['downloadURL']
                }
                // console.log("this.brick.imagen_carrusel = ", this.brick.imagen_carrusel)
                //console.log("estado del producto visible = ", !this.isToggleBtnChecked, "this.brick.visible = ", this.brick.visible)
                // if ((this.brick.visible != this.oldVisible && this.brick.visible == false) && (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT' || this.auth.countryCode == 'HN')) {
                if ((this.brick.visible != this.oldVisible && this.brick.visible == false) && (this.auth.countryCode)) {
                    let presentationOld = this.presentations
                    //console.log(presentationOld)
                    //console.log(JSON.stringify(presentationOld))
                    this.creation_date = new Date();
                    let nameBrick = this.brick.name
                    // remover emoji de los textos
                    nameBrick = nameBrick.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
                    //console.log("nameBrick", nameBrick)

                    //console.log(`https://mails.barlleno.app/mails_inventoryB64.php?mode=inventoryHidden&presentations=${window.btoa(JSON.stringify(presentationOld))}&countryCode=${this.auth.countryCode}&role=${this.auth.role}&email=${this.auth.email}&creation_date=${window.btoa(this.creation_date)}&nameBrick=${window.btoa(nameBrick)}`)
                    this.http.get(`https://mails.barlleno.app/mails_inventoryB64.php?mode=inventoryHidden&presentations=${window.btoa(JSON.stringify(presentationOld))}&countryCode=${this.auth.countryCode}&role=${this.auth.role}&email=${this.auth.email}&creation_date=${window.btoa(this.creation_date)}&nameBrick=${window.btoa(nameBrick)}`)
                        .subscribe((response) => {
                            // //console.log("enviar correo");
                            this.presentations.forEach(element => {
                                // //console.log(element)
                                element.inventory = Number(0)
                                // //console.log(element)
                            });
                            this.api.updateBrick(this.brick, this.presentations, this.on_presentations, this.brick_image.src, this.superbPresentations, this.superb_on_presentations).then(data => {
                                loader.dismiss();
                                this.SwalService.fire('¡Listo!', 'Tu producto ha sido actualizado', 'success');
                                this.modalController.dismiss();
                            });
                        })
                    // } else if ((this.brick.visible != this.oldVisible && this.brick.visible == true) && (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT' || this.auth.countryCode == 'HN')) {
                } else if ((this.brick.visible != this.oldVisible && this.brick.visible == true) && (this.auth.countryCode)) {
                    let presentationOld = this.presentations
                    //console.log(presentationOld)
                    //console.log(JSON.stringify(presentationOld))
                    this.creation_date = new Date();
                    let nameBrick = this.brick.name
                    nameBrick = nameBrick.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');

                    //console.log(`https://mails.barlleno.app/mails_inventoryB64.php?mode=inventoryShow&presentations=${window.btoa(JSON.stringify(presentationOld))}&countryCode=${this.auth.countryCode}&role=${this.auth.role}&email=${this.auth.email}&creation_date=${window.btoa(this.creation_date)}&nameBrick=${window.btoa(nameBrick)}`)
                    this.http.get(`https://mails.barlleno.app/mails_inventoryB64.php?mode=inventoryShow&presentations=${window.btoa(JSON.stringify(presentationOld))}&countryCode=${this.auth.countryCode}&role=${this.auth.role}&email=${this.auth.email}&creation_date=${window.btoa(this.creation_date)}&nameBrick=${window.btoa(nameBrick)}`)
                        .subscribe((response) => {
                            // //console.log("enviar correo");
                            this.api.updateBrick(this.brick, this.presentations, this.on_presentations, this.brick_image.src, this.superbPresentations, this.superb_on_presentations).then(data => {
                                loader.dismiss();
                                this.SwalService.fire('¡Listo!', 'Tu producto ha sido actualizado', 'success');
                                this.modalController.dismiss();
                            });
                        })
                } else {
                    this.api.updateBrick(this.brick, this.presentations, this.on_presentations, this.brick_image.src, this.superbPresentations, this.superb_on_presentations).then(data => {
                        loader.dismiss();
                        this.SwalService.fire('¡Listo!', 'Tu producto ha sido actualizado', 'success');
                        this.modalController.dismiss();
                    });
                }
            })
        })
    }

    updateExplore() {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.brick.brand = this.brickForm.value.brand;
                this.brick.name = this.brickForm.value.name;
                this.brick.description = this.brickForm.value.description;
                this.brick.visible = this.brickForm.value.visible;
                this.brick.position = this.brickForm.value.position;

                this.api.getRef('explore').ref
                    .where('brick', '==', this.brick.$key)
                    .where('type', '==', 'reference')
                    .get()
                    .then(data => {
                        data.forEach(element => {
                            this.api.updateDocument('explore', element.id, {
                                visible: this.brick.visible
                            })
                        });
                    })

                this.api.updateExplore(this.brick, this.brick_image.src).then(data => {
                    loader.dismiss();
                    this.SwalService.fire('¡Listo!', 'Tu producto ha sido agregado', 'success');
                    this.modalController.dismiss();
                });
            })
        })
    }

    addPresentation() {
        this.api.addDocument(`bricks/${this.brick.$key}/presentations`, {
            presentation: `${this.presentationName} ${this.presentation_unidades}`,
            price: 0,
            reward: 0,
            codeSap: '',
            bar_code: ''
        }).then(data => {
            this.presentationName = '';
            this.presentation_unidades = null
        })

    }

    addOnPresentation() {
        this.api.addDocument(`bricks/${this.brick.$key}/on_presentations`, {
            presentation: this.on_presentationName,
            price: 0,
            codeSap: '',
            bar_code: ''
        }).then(data => {
            this.on_presentationName = '';
        })
    }

    changeMain(presentation, $event) {
        this.presentations.forEach(element => {
            element.main = false;
        });
        presentation.visible = true;
        presentation.main = true;
    }

    changeMainCheck(namePresentation, presentation, $event) {
        //console.log("namePresentation", namePresentation)
        this.presentations.forEach(element => {
            if (element.presentation != namePresentation) {
                element.main = false;
            } else {
                // element.main = true;
            }
        });

    }


    changeInventoryVisible(presentation, $event) {
        //console.log("changeInventoryVisible evaluando al momento de cargar la informacion")

        const isChecked = $event.detail.checked;
        //console.log(isChecked);
        if (isChecked) {
            //console.log("changeInventoryVisible is check")
            presentation.inventory_visible = true;
        }
        else {
            //console.log("changeInventoryVisible Not is check")
            presentation.inventory_visible = false;
        }
    }

    changeVisible(presentation, $event) {
        const isChecked = $event.detail.checked;
        //console.log("changeVisible isChecked = ", isChecked);
        //console.log("this.on_presentations = ", this.on_presentations)
        if (isChecked) {
            //console.log("changeVisible, is check")
            presentation.visible = true;
        }
        else {
            //console.log("changeVisible, Not is check")
            presentation.visible = false;
            // if(presentation.main == false){
            presentation.inventory = 0
            // }
        }
        this.changeVisibleOnTriger(this.on_presentations, presentation)

    }

    changeVisibleOnTriger(on_presentations, presentation) {
        //console.log("changeVisibleOnTriger > on_presentations = ", on_presentations, "presentation", presentation)
        let thisPresentation = presentation['presentation']
        //console.log("thisPresentation = ", thisPresentation, "thisPresentation.name = ", presentation.presentation)
        var respFind = on_presentations.find(function (elem, index) {
            if (elem.presentation == thisPresentation) {
                //console.log("encotre el valor en on_presentations")
                return true
            }
        })
        //console.log("respFind = ", respFind)
        if (respFind) {
            respFind.visible = presentation.visible
        }
    }

    changeVisibleOn(presentation, $event) {
        const isChecked = $event.detail.checked;
        //console.log(isChecked);
        if (isChecked) {
            //console.log("changeVisibleOn, is check")
            presentation.visible = true;
        }
        else {
            //console.log("changeVisibleOn, Not is check")
            presentation.visible = false;
        }
    }

    // changeOnMain(presentation, $event) {
    //     this.on_presentations.forEach(element => {
    //         element.main = false;
    //     });
    //     presentation.main = true;

    // }


    changeOnMain(namePresentation, presentation, $event) {
        //console.log("namePresentation", namePresentation)
        this.on_presentations.forEach(element => {
            if (element.presentation != namePresentation) {
                element.main = false;
            } else {
                // element.main = true;
            }
        });

    }

    changePrice(presentation, $event) {
        //console.log("changePrice", presentation)
        const { price } = presentation
        // if (price && price.includes(',')) {
        // price.replace(',', '.')
        // this.mensajeAlerta('error', 'Caracter no valido, utilizar Punto (.)');
        // return;
        // }
    }

    changePriceBin(presentation, $event) {
        //console.log("changePriceBin", presentation)
        const { price_bin } = presentation
        // if (price_bin && price_bin.includes(',')) {
        //     price_bin.replace(',', '.')
        //     this.mensajeAlerta('error', 'Caracter no valido, utilizar Punto (.)');
        //     return;
        // }
    }

    removePresentation(presentation) {
        this.SwalService.fire({
            title: 'Esta seguro que deseas eliminar?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
            if (result.value) {

                this.api.deleteDocument(`bricks/${this.brick.$key}/presentations/`, presentation.$key).then(data => {

                    for (let index = 0; index < this.presentations.length; index++) {
                        const element = this.presentations[index];
                        if (element.$key == presentation.$key) {
                            this.presentations.splice(index, 1)
                        }
                    }

                    this.SwalService.fire(
                        'Eliminado!',
                        'La presentación ha sido eliminada',
                        'success'
                    )
                });
            }
        })
    }

    removeOnPresentation(presentation) {
        this.SwalService.fire({
            title: 'Esta seguro que deseas eliminar?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
            if (result.value) {

                this.api.deleteDocument(`bricks/${this.brick.$key}/on_presentations/`, presentation.$key).then(data => {

                    for (let index = 0; index < this.on_presentations.length; index++) {
                        const element = this.on_presentations[index];
                        if (element.$key == presentation.$key) {
                            this.on_presentations.splice(index, 1)
                        }
                    }

                    this.SwalService.fire(
                        'Eliminado!',
                        'La presentación ha sido eliminada',
                        'success'
                    )
                });
            }
        })
    }

    async addReference() {
        const modal = await this.modalController.create({
            component: AddReferenceComponent,
            cssClass: 'addModal',
            componentProps: {
                category: this.category,
            }
        });
        modal.onDidDismiss().then(data => {
            let referenciaKey = data.data.$key
            if (!data.data.hasOwnProperty('$key')) {
                referenciaKey = data.data.objectID
            }
            if (data.data) {
                let brick = {
                    brick: this.brick.$key,
                    visible: data.data.visible,
                    reference: referenciaKey
                }
                //console.log(brick);

                this.loader().then(loader => {
                    loader.present().then(() => {
                        this.api.addDocument('recommended', brick).then(data => {
                            loader.dismiss();
                            this.getReferences();
                            this.SwalService.fire('¡Listo!', 'Tu Referencia ha sido agregada', 'success');
                        });
                    })
                })


            }

        })
        return await modal.present();
    }

    async addReferenceKits() {
        const modal = await this.modalController.create({
            component: AddReferenceComponent,
            cssClass: 'addModal',
            componentProps: {
                category: this.category,
            }
        });
        modal.onDidDismiss().then(data => {
            let referenciaKey = data.data.$key
            if (!data.data.hasOwnProperty('$key')) {
                referenciaKey = data.data.objectID
            }
            if (data.data) {
                let brick = {
                    brick: this.brick.$key,
                    visible: data.data.visible,
                    reference: referenciaKey
                }
                //console.log(brick);
                //console.log("addReferenceKits add element in array")
                this.referenceKits.push(brick)
                this.getReferencesKits();
            }
        })
        return await modal.present();
    }

    getReferencesKits() {
        let referenceKitsAux = []
        this.referenceKits = [...new Set(this.referenceKits)]
        // inicio proceso
        for (let index = 0; index < this.referenceKits.length; index++) {
            const brick_kit = this.referenceKits[index];
            //console.log("brick_kit = ", brick_kit)

            this.api.getDocument('bricks', brick_kit.reference).then(data => {
                brick_kit.brick = data;
                referenceKitsAux.push(brick_kit);
            }, err => {
            });
        }
        this.referenceKits = referenceKitsAux
        //console.log("referenceKits = ", this.referenceKits.length, this.referenceKits)
    }

    deleteReferenceKits(reference) {

        this.referenceKits = this.referenceKits.filter(item => item !== reference)
        //console.log("this.referenceKits = ", this.referenceKits, "length = ", this.referenceKits.length, "typeof = ", typeof this.referenceKits)

    }

    deleteReference(reference) {
        reference.description = unescape(encodeURIComponent(reference.description))
        reference.name = unescape(encodeURIComponent(reference.name))

        this.api.deleteDocument('recommended', reference.$key).then(data => {
            this.getReferences();
            //console.log(`https://mails.barlleno.app/mails_inventory.php?mode=deleteLog&data=${window.btoa(JSON.stringify(reference))}&role=${this.auth.role}`);
            this.http.get(`https://mails.barlleno.app/mails_inventory.php?mode=deleteLog&data=${window.btoa(JSON.stringify(reference))}&role=${this.auth.role}`)
                .subscribe((response) => {
                })
        })
    }

    visibleChange(event): void {
        // if (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT' || this.auth.countryCode == 'HN') {
        if (this.auth.countryCode) {
            const isChecked = event.detail.checked;
            //console.log(isChecked);
            if (isChecked) {
                //console.log("is check")
                this.isToggleBtnChecked = false
            }
            else {
                //console.log("Not is check")
                this.isToggleBtnChecked = true
            }
        }
    }

    mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
        Swal.fire({
            position: 'top-end',
            icon: icono,
            title: mensaje,
            showConfirmButton: false,
            timer: 3500,
            toast: true
        });
    }

    // supplier changeSelect
    onChange(evt) {
        this.supplier = evt
    }

    onChangeNivel(evt) {
        this.nivel = evt
        //console.log("this.nivel = ", this.nivel)
    }

    // funciones para manejo de detalle del brick developUX
    // ====================== Manejo de imagenes =========================
    cargarImagen(event: any, position: number) {
        const image: File = event.srcElement.files[0];

        if (!image.type.includes('image')) {
            this.mensajeAlerta('error', 'Favor seleccionar un archivo de imagen');
            return;
        }

        const reader = new FileReader();

        if (position == 4) {
            reader.onloadend = () => {
                this.mainImage = reader.result as string;
                this.mainImageFile = image;
                this.listImage[0] = reader.result as string;
                this.listImageFile[0] = image
            }
        } else {
            reader.onloadend = () => {
                this.listImage[position] = reader.result as string;
                this.listImageFile[position] = image
            }
        }

        reader.readAsDataURL(image)
    }


    // =============================================================
    activarCaracteristicas() {
        if (this.wineisActive == false) {
            this.wineisActive = true;
            this.brickForm.addControl('vino', this.formBuilder.group({
                ligero: ['', [Validators.required, Validators.max(5)]],
                dulce: ['', [Validators.required, Validators.max(5)]]
            }));
        } else {
            this.brickForm.removeControl('vino');
            this.wineisActive = false;
        }
    }

    // ===================== Validaciones de formulario ===================
    validarCampo(campo: string) {
        return this.brickForm.get(campo).invalid &&
            this.brickForm.get(campo).touched
    }

    // ================== Permitir solo numeros ============================
    onlyNumbers(event: KeyboardEvent): boolean {
        const key = event.key;
        const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

        if (numbers.includes(key)) return true;
        return false;
    }

    // ============== Permitir solo numeros con decimales ==================
    onlyNumbersDecimal(event: KeyboardEvent): boolean {
        const key = event.key;
        const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "."];

        if (numbers.includes(key)) return true;
        return false;
    }

    // =============================================================
    addValidationGroup(campo: string) {
        if (this.brickForm.get(campo).status == 'DISABLED') {
            this.brickForm.get(campo).enable();
            this.brickForm.get(campo).setValidators([Validators.required]);
        } else {
            this.brickForm.get(campo).setValue('');
            this.brickForm.get(campo).clearValidators();
            this.brickForm.get(campo).disable();
        }
        this.brickForm.get(campo).updateValueAndValidity();
    }

    // =============================================================
    loadDataWine(product) {
        if (product.vino) {
            this.activarCaracteristicas();
            this.brickForm.get('activarVino').setValue(true);

            this.brickForm.get('vino').setValue({
                ligero: product.vino.ligero,
                dulce: product.vino.dulce
            });
        }
    }
    // =============================================================
    // Carga de datos para grupos de formulario - Grupo detalles
    loadDetails(producto) {
        if (producto.detalles == undefined) return;

        const fieldsText = ['region', 'cristaleria', 'maridaje', 'temperatura', 'notasCatacion'];

        Object.keys(producto.detalles).forEach(key => {
            if (fieldsText.includes(key) && producto.detalles[key] || producto.detalles[key] > 0) {
                this.brickForm.get('detalles').get(key).setValue(producto.detalles[key]);
                this.brickForm.get('detalles').get(key).enable();
            }
        });
    }

    // =============================================================
    // Carga de imagenes
    loadImagesCarrousel(product) {
      // console.log("product-->", product);

        this.mainImage = product.image != undefined ? product.image.downloadURL : ''

        if (product.imagen_carrusel) {
            // this.listImage[0] = product.imagen_carrusel.downloadURL[0] || '';
            this.listImage[0] = (product.imagen_carrusel.downloadURL[0] != undefined && product.imagen_carrusel.downloadURL[0] != '') ? product.imagen_carrusel.downloadURL[0] : product.image.downloadURL || '';
            if (product.imagen_carrusel.downloadURL[1] != undefined && product.imagen_carrusel.downloadURL[1] != '') {
                this.listImage[1] = product.imagen_carrusel.downloadURL[1];
            }
            if (product.imagen_carrusel.downloadURL[2] != undefined && product.imagen_carrusel.downloadURL[2] != '') {
                this.listImage[2] = product.imagen_carrusel.downloadURL[2];
            }
            // this.listImage[2] = product.imagen_carrusel.downloadURL[2] || '';
        } else {
            this.listImage[0] = product.image != undefined ? product.image.downloadURL : '';
        }
    }

    // ======================= Revisar validaciones ======================= 
    reviewValidations(): boolean {
        if (this.brickForm.invalid) {
            this.brickForm.markAllAsTouched();
            this.mensajeAlerta('error', 'Favor completar los campos marcados en rojo');
            return false;
        }


        return true;
    }


    addSuperbPresentation() {
        this.api.addDocument(`bricks/${this.brick.$key}/superb_presentations`, {
            presentation: this.superb_PresentationName,
            price: 0,
            reward: 0,
            codeSap: '',
            bar_code: ''
        }).then(data => {
            this.superb_PresentationName = '';
        })
    }

    addOnSuperbPresentation() {
        this.api.addDocument(`bricks/${this.brick.$key}/superb_on_presentations`, {
            presentation: this.superb_on_presentationName,
            price: 0,
            codeSap: '',
            bar_code: ''
        }).then(data => {
            this.superb_on_presentationName = '';
        })
    }
    //superbPresentations
    //superb_on_presentations

    changeSuperbMainCheck(namePresentation, presentation, $event) {
        //console.log("namePresentation", namePresentation)
        this.superbPresentations.forEach(element => {
            if (element.presentation != namePresentation) {
                element.main = false;
            } else {
                // element.main = true;
            }
        });
    }

    changeOnSuperbMain(namePresentation, presentation, $event) {
        //console.log("namePresentation", namePresentation)
        this.superb_on_presentations.forEach(element => {
            if (element.presentation != namePresentation) {
                element.main = false;
            } else {
                // element.main = true;
            }
        });
    }

    removeSuperBPresentation(presentation) {
        this.SwalService.fire({
            title: 'Esta seguro que deseas eliminar?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
            if (result.value) {

                this.api.deleteDocument(`bricks/${this.brick.$key}/superb_presentations/`, presentation.$key).then(data => {

                    for (let index = 0; index < this.superbPresentations.length; index++) {
                        const element = this.superbPresentations[index];
                        if (element.$key == presentation.$key) {
                            this.superbPresentations.splice(index, 1)
                        }
                    }

                    this.SwalService.fire(
                        'Eliminado!',
                        'La presentación Superb ha sido eliminada',
                        'success'
                    )
                });
            }
        })
    }

    removeSuperbOnPresentation(presentation) {
        this.SwalService.fire({
            title: 'Esta seguro que deseas eliminar?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
            if (result.value) {

                this.api.deleteDocument(`bricks/${this.brick.$key}/superb_on_presentations/`, presentation.$key).then(data => {

                    for (let index = 0; index < this.superb_on_presentations.length; index++) {
                        const element = this.superb_on_presentations[index];
                        if (element.$key == presentation.$key) {
                            this.superb_on_presentations.splice(index, 1)
                        }
                    }

                    this.SwalService.fire(
                        'Eliminado!',
                        'La presentación Superb ON ha sido eliminada',
                        'success'
                    )
                });
            }
        })
    }

    changeSuperbVisible(presentation, $event) {
        const isChecked = $event.detail.checked;
        //console.log("changeVisible isChecked = ", isChecked);
        //console.log("this.superb_on_presentations = ", this.superb_on_presentations)
        if (isChecked) {
            //console.log("changeVisible, is check")
            presentation.visible = true;
        }
        else {
            //console.log("changeVisible, Not is check")
            presentation.visible = false;
            // if(presentation.main == false){
            presentation.inventory = 0
            // }
        }
        this.changeVisibleOnTriger(this.superb_on_presentations, presentation)

    }

    onCopyFailure() {
        // console.log('copy fail!');
    }

    copied(e) {
        // console.log("event ",e)
        this.mensajeAlerta('success', 'Copiado');
        this._clipboardService.copyFromContent(this.clipboard)
    }

}
