// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};


export const firebaseConfig = {
  apiKey: "AIzaSyCA6hFFJTP7or8ZRH7m0pG9EMGK8v1mV5E",
  authDomain: "barlleno.firebaseapp.com",
  databaseURL: "https://barlleno.firebaseio.com",
  projectId: "barlleno",
  storageBucket: "barlleno.appspot.com",
  messagingSenderId: "470315426875"
}

// export const algoliaConfig = {
//   appId: '8VE2AFU3UV',
//   apiKey: '02b831506fb8ea3e430cf61a8acf73f4'
// }
// nuevas credenciales algolia premte
export const algoliaConfig = {
  appId: 'QT8K1WEBDL',
  apiKey: '7b9d04c21fc0c1a453ee260f4ac64391'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
