import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrderComponent } from '../order/order.component';
import { ChatComponent } from '../chat/chat.component';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SwalService } from 'src/app/services/alert/swal.service';

@Component({
    selector: 'app-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent implements OnInit {

    coupon: any;
    orders: any = [];
    couponForm: FormGroup;
    unlimited: any = false;
    total_orders: any = 0;
    total: any = 0;
    list_supplier: any = []
    list_regalias: any = []
    supplier: any = ''
    regalia: any = ''
    // listTypeCoupons: any = ['free_delivery', 'supplier_discount', 'second_round', 'bin']
    // listTypeCoupons: any = ['free_delivery', 'supplier_discount', 'second_round']
    // listTypeCoupons: any = ['free_delivery', 'supplier_discount', 'second_round', 'unique', 'strike_first', 'all_store']
    listTypeCoupons: any = ['free_delivery', 'supplier_discount', 'second_round', 'unique', 'strike_first']

    // listTypeCoupons: any = ['free_delivery', 'supplier_discount']
    // listTypeCoupons: any = ['free_delivery']
    type: any
    listSupplierProduct: any = []
    supplierProduct: any = ''
    discount_percentage: any = 0
    // descuento sobre el total de la compra
    second_round_discount: any = 0
    second_round__mount_min: any = 0
    // BIN
    bankList: any = ''
    listBank: any = []
    bank: any = ''


    constructor(
        public api: ApiService,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public modalController: ModalController,
        private http: HttpClient,
        public auth: AuthService,
        public formBuilder: FormBuilder,
        private SwalService: SwalService
    ) {
        if (this.auth.countryCode == 'SV') {
            this.listTypeCoupons = ['free_delivery', 'supplier_discount', 'second_round', 'bin', 'unique', 'strike_first', 'all_store']
        }
        else if (this.auth.countryCode == 'HN') {
            this.listTypeCoupons = ['free_delivery', 'supplier_discount', 'second_round', 'bin', 'unique', 'strike_first', 'all_store', 'bin_price']
        }

        this.coupon = navParams.get('coupon');
        this.type = this.coupon.type

        if (this.coupon.regalia) {
            this.regalia = this.coupon.regalia
        }

        if(this.coupon.supplier){
            this.supplier = this.coupon.supplier
        }

        // descuento por porcentaje de proveedor
        this.discount_percentage = this.coupon.discount_percentage
        // descuento sobre el total de la compra
        this.second_round_discount = this.coupon.second_round_discount
        this.second_round__mount_min = this.coupon.minimum

        this.unlimited = this.coupon.uses.unlimited;
        this.bank = this.coupon.bank

        this.couponForm = this.formBuilder.group({
            code: [this.coupon.code, [Validators.required]],
            available: [this.coupon.available, [Validators.required]],
            type: [this.coupon.type, [Validators.required]],
            uses: [this.coupon.uses, [Validators.required]],
            supplier: [this.coupon.supplier],
            regalia: [this.coupon.regalia],
            bank: [''],
            discount_percentage: [''],
            second_round_discount: [''],
            minimum: [''],
            countryCode: this.auth.countryCode
        });

        this.api.getRef('orders').ref
            .where('coupon', '==', this.coupon.code)
            .orderBy('added_date', 'desc')
            .get()
            .then(snapshots => {
                this.total_orders = snapshots.size;
                snapshots.forEach(element => {
                    let order = element.data();
                    order.date = order.added_date.toDate();
                    order.$key = element.id;

                    if (order.status !== 'canceled') {
                        if (order.total) {
                            this.total += Number(order.total);
                        }
                    }

                    this.orders.push(order);
                });

                //console.log(this.orders);
            })
        this.getBankList()
    }

    ngOnInit() {
        this.getSupplier()
        this.getRegaliaStrikeFirst()
    }

    changeUnlimited(e) {
        if (e.detail.checked) {
            this.unlimited = true;
            this.coupon.uses = {
                unlimited: true
            };
        } else {
            this.unlimited = false;
            this.coupon.uses = 0;
        }
    }

    updateCoupon() {
        this.loader().then(loader => {
            loader.present().then(() => {
                let data = this.couponForm.value;
                data.created_at = new Date()
                if (this.unlimited) {
                    data.uses = { unlimited: true };
                }
                data.type = this.type

                if (data.code === '' || data.code == undefined) {
                    this.mensajeAlerta('error', 'Favor completar Nombre de Cupon');
                    loader.dismiss();
                    return;
                }else {
                    data.code = data.code.toUpperCase()
                }
                
                if (data.type) {
                    switch (data.type) {
                        case "bin":
                            //console.log("1")
                            if (data.code === '' || data.code == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Campos');
                                loader.dismiss();
                                return
                            }
                            break;
                        case "free_delivery":
                            //console.log("2")
                            if (data.uses === '' || data.uses == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Cantidad de Usos');
                                loader.dismiss();
                                return
                            }
                            break;
                        case "second_round":
                            //console.log("3")
                            if (data.second_round_discount === '' || data.second_round_discount == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Monto Descuento');
                                loader.dismiss();
                                return
                            }
                            if (data.minimum === '' || data.minimum == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Minimo Compra');
                                loader.dismiss();
                                return
                            }
                            break;
                        case "share_code":
                            //console.log("4")
                            break;
                        case "supplier_discount":
                            //console.log("5", data.discount_percentage)
                            if (data.discount_percentage === '' || data.discount_percentage == undefined) {
                                this.mensajeAlerta('error', 'Favor completar % Descuento');
                                loader.dismiss();
                                return
                            }
                            if (this.supplier === '' || this.supplier == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Proveedor');
                                loader.dismiss();
                                return
                            }
                            break;
                        case "strike_first":
                            //console.log("6", data.discount_percentage)
                            if (data.minimum === '' || data.minimum == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Minimo Compra');
                                loader.dismiss();
                                return
                            }
                            if (this.supplier === '' || this.supplier == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Regalia');
                                loader.dismiss();
                                return
                            }
                            break;
                        case "unique":
                            //console.log("7")
                            if (data.second_round_discount === '' || data.second_round_discount == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Monto Descuento');
                                loader.dismiss();
                                return
                            }
                            if (data.minimum === '' || data.minimum == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Minimo Compra');
                                loader.dismiss();
                                return
                            }
                            break;
                    }
                }

                //console.log("updateCoupon => ", data)
                this.api.updateDocument('coupons', this.coupon.$key, data).then(data => {
                    loader.dismiss();
                    this.coupon = data;
                    this.SwalService.fire(
                        'Exito!',
                        'Cupón actualizado correctamente.',
                        'success'
                    )
                    this.modalController.dismiss();
                })
            })
        })

    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    async selectOrder(order) {

        const modal = await this.modalController.create({
            component: OrderComponent,
            cssClass: 'addModal',
            componentProps: {
                order: order
            }
        });
        return await modal.present();

    }

    async selectChat(chat) {
        //console.log('selecionado chat');

        const modal = await this.modalController.create({
            component: ChatComponent,
            cssClass: 'addModal',
            componentProps: {
                conversation_key: chat.$key
            }
        });
        return await modal.present();

    }

    // se comenta - la operacion debe ser realizada desde monitor
    changeStatus(order, status) {
        //console.log("cambio de estado se desactiva")
        // Swal.fire({
        //     title: 'Esta seguro que deseas cambiar el estado?',
        //     text: "¡No podrás revertir esto!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Si, estoy seguro'
        // }).then((result) => {
        //     if (result.value) {
        //         this.api.updateDocument(`orders`, order.$key, { status: status }).then(data => {
        //             order.status = status;
        //             let message;
        //             if (status == 'making') {
        //                 message = `Tu orden #${order.number} se está preparando 🔥`
        //             } else if (status == 'way') {
        //                 message = `Tu orden #${order.number} ya va de camino 🚚`
        //             } else if (status == 'delivered') {
        //                 message = `Tu orden #${order.number} ha sido entregada 🏡`
        //             }

        //             this.api.getAllDocuments(`accounts/${order.account.$key}/push_keys`).then(keys => {
        //                 if (keys) {
        //                     let push_keys = [];
        //                     keys.forEach(element => {
        //                         push_keys.push(element.push_key);
        //                     });

        //                     this.api.sendPush(`Actualización de estado`, message, push_keys);
        //                 }
        //             })

        //             Swal.fire(
        //                 'Estado actualizado!',
        //                 'Tu estado ha sido actualizado correctamente',
        //                 'success'
        //             )
        //         });
        //     }

        // })
    }
    // se remueve la llamada en el FrondEnd
    capture(order) {
        this.loader().then(loader => {
            loader.present().then(() => {

                this.SwalService.fire({
                    title: 'Esta seguro que deseas Aceptar la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {
                    if (result.value) {
                        let getData = `orderNo=${order.number}&amount=${order.total}&type=1`;
                        let url;
                        if (this.auth.countryCode == 'SV') {
                            url = `https://payments.barlleno.app/transactionModification.php?${getData}`;
                        } else if (this.auth.countryCode == 'GT') {
                            url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
                        }

                        this.http.get(url).subscribe((response) => {
                            if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                                this.api.updateDocument('orders', order.$key, {
                                    'transaction_status': 'captured',
                                    'status': 'making'
                                }).then(data => {
                                    order.transaction_status = 'captured';
                                    this.SwalService.fire(
                                        'Transacción Capturada',
                                        'Captura de fondos realizar con exito',
                                        'success'
                                    )
                                })
                            } else {
                                this.SwalService.fire(
                                    'Error!',
                                    'No se pudo completar la captura de fondos, comnunicate con el equipo de barlleno',
                                    'error'
                                )
                            }
                            loader.dismiss();
                        }, err => {
                            loader.dismiss();
                        });
                    } else {
                        loader.dismiss();
                    }
                });
            })
        })
    }
    // se remueve la llamada en el FrondEnd
    revert(order) {

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer prv_prod_PWw13Ai1yGYJuTF6BQJ99nG8w9MkTHAw'
            })
        };

        this.loader().then(loader => {
            loader.present().then(() => {
                this.SwalService.fire({
                    title: 'Esta seguro que deseas Revertir la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {
                    if (result.value) {
                        let getData = `orderNo=${order.number}&amount=${order.total}&type=3`;
                        let url;
                        let id_transaction = this.orders.transaction_id;
                        if (this.auth.countryCode == 'CO') {
                            url = `https://production.wompi.co/v1/transactions/?${id_transaction}/void`;
                        } else if (this.auth.countryCode == 'SV') {
                            url = `https://payments.barlleno.app/transactionModification.php?${getData}`;
                        } else if (this.auth.countryCode == 'GT') {
                            url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
                        }

                        this.http.get(url)
                        url
                            .subscribe((response) => {
                                if (response['TransactionModificationResult']['ResponseCode'] == 1 || response['data']['status'] == 'VOIDED') {
                                    this.api.updateDocument('orders', order.$key, {
                                        'transaction_status': 'Reverted',
                                        'status': 'canceled'

                                    }).then(data => {
                                        order.transaction_status = 'reverted';
                                        this.SwalService.fire(
                                            'Transacción Revertida',
                                            'Reversión de fondos realizar con exito',
                                            'success'
                                        )
                                    })
                                } else {
                                    this.SwalService.fire(
                                        'Error!',
                                        'No se pudo completar, comunicate con el equipo de Barlleno',
                                        'error'
                                    )
                                }
                                loader.dismiss();
                            }, err => {
                                loader.dismiss();
                            });
                    }
                    else {
                        loader.dismiss();
                    }
                })
            })
        })
    }

    isActive(status, order_status) {

        if (order_status == 'pending') {
            if (status == 'pending') {
                return 'true';
            }
        }

        if (order_status == 'making') {
            if (status == 'pending' || status == 'making') {
                return 'true';
            }
        }

        if (order_status == 'way') {
            if (status == 'pending' || status == 'making' || status == 'way') {
                return 'true';
            }
        }

        if (order_status == 'delivered') {
            if (status == 'pending' || status == 'making' || status == 'way' || status == 'delivered') {
                return 'true';
            }
        }
    }

    cancelOrder(order) {
        this.SwalService.fire({
            title: `Esta seguro que deseas CANCELAR la orden ${order.number}?`,
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
            if (result.value) {
                //console.log(order);
                this.api.cancelOrder(order).then(data => {
                    order.status = 'canceled';
                })
            }

        })
    }

    onChangeType(evt) {
        //console.log(evt)
        this.type = evt
        this.getSupplierProduct()
    }

    onChangeSupplier(evt) {
        //console.log(evt)
        this.supplier = evt
    }

    onChangeBank(evt) {
        //console.log(evt)
        this.bank = evt
    }
    
    onChangeRegalia(evt) {
        //console.log(evt)
        this.regalia = evt
    }

    getSupplierProduct() {
        //console.log(this.supplier);

        this.listSupplierProduct = []
        this.api.getRef('bricks').ref
            .where('countryCode', '==', this.auth.countryCode)
            //.where("supplier", "==", this.supplier)
            .get()
            .then(snippets => {
                let element_size = snippets.size
                // //console.log(element_size)
                snippets.forEach(element => {
                    let supplier = element.data().name;
                    if (supplier) {
                        // //console.log(supplier)
                        this.listSupplierProduct.push(supplier)
                    }
                });
                let uniq = [...new Set(this.listSupplierProduct)]

                this.listSupplierProduct = uniq.sort()

                // //console.log(this.listSupplierProduct)
                // //console.log(uniq.sort())

            })
    }

    getBankList() {
        //console.log(this.bankList);

        this.listBank = []
        this.api.getRef('configurations').ref
            .where('countryCode', '==', this.auth.countryCode)
            .get()
            .then(snippets => {
                let element_size = snippets.size
                //console.log("getBankList--> element_size")
                //console.log(element_size)
                if (element_size > 0) {
                    snippets.forEach(element => {
                        //console.log("element");
                        //console.log(element.data().bin_list);

                        let listElement = element.data().bin_list;
                        if (listElement) {
                            //console.log(typeof (listElement));
                            this.listBank = Object.keys(listElement).sort()
                            // //console.log(this.listBank)
                        }
                    });
                } else {
                    let obj = {
                        0: 'NA',
                    };
                    this.listBank = obj
                }
                //console.log(this.listBank)
            })
    }

    getSupplier() {
        this.list_supplier = []
        this.api.getRef('bricks').ref
            .where('countryCode', '==', this.auth.countryCode)
            // .where("type", "==", "brick")
            .where("type", "in", ["club", "brick"])
            .get()
            .then(snippets => {
                let element_size = snippets.size
                // //console.log(element_size)
                snippets.forEach(element => {
                    let supplier = element.data().supplier;
                    if (supplier) {
                        // //console.log(supplier)
                        this.list_supplier.push(supplier)
                    }
                });
                let uniq = [...new Set(this.list_supplier)]

                this.list_supplier = uniq.sort()

                // //console.log(this.list_supplier)

            })
    }

    getRegaliaStrikeFirst() {
        this.list_regalias = []
        this.api.getRef('configurations').ref
            .where('countryCode', '==', this.auth.countryCode)
            .get()
            .then(snippets => {
                let element_size = snippets.size
                //console.log("getBankList--> element_size")
                //console.log(element_size)
                if (element_size > 0) {
                    snippets.forEach(element => {
                        //console.log("list regalias");
                        //console.log(element.data().regalias);

                        let listElement = element.data().regalias;
                        if (listElement) {
                            listElement.forEach(item => {
                                //console.log(typeof (item));
                                item['texto'] = item.name + " " + item.presentation
                                this.list_regalias.push(item)
                            });
                        }
                    });
                } else {
                    let obj = {
                        0: {
                            name: 'NA',
                            presentation: 'NA'
                        },
                    };
                    this.list_regalias = obj
                }
                //console.log(this.list_regalias)
            })
    }

    mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
        Swal.fire({
            position: 'top-end',
            icon: icono,
            title: mensaje,
            showConfirmButton: false,
            timer: 3500,
            toast: true
        });
    }

}
