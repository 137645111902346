import { Injectable } from '@angular/core';
import { AngularFireFunctions } from 'angularfire2/functions';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(private fireFunctions: AngularFireFunctions) { }

  filterArray(query, orgArray, searchParams) {
    if (!query) {
      return Array.from(orgArray)
    } else {
      let results: any[] = []

      orgArray.forEach((d: any) => {
        for (let i = 0; i < searchParams.length; i++) {
          let param = searchParams[i]
          if (typeof param == "object") {
            if (d[param.p][param.sb] != undefined && String(d[param.p][param.sb]).toLowerCase().includes(query.toLowerCase())) {
              results.push(d)
              break
            }
          } else {
            if (d[param] != undefined && String(d[param]).toLowerCase().includes(query.toLowerCase())) {
              results.push(d)
              break
            }
          }
        }
      })

      return results
    }
  }

  sendEmail(id: string): Promise<void> {
    const sendOrder = this.fireFunctions.httpsCallable('sendOrder')
    return new Promise((resolve, reject) => {
      sendOrder({ id: id })
        .toPromise()
        .then(() => resolve())
        .catch((err) => reject(err))
    });
  }
}
