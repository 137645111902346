import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController } from '@ionic/angular';
import algoliasearch from 'algoliasearch';
import { algoliaConfig } from 'src/environments/environment.prod';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'app-add-reference',
    templateUrl: './add-reference.component.html',
    styleUrls: ['./add-reference.component.scss'],
})

export class AddReferenceComponent implements OnInit {

    bricks: any = [];
    current_bricks: any = [];
    loading: any = true;
    lastRequest: any;
    searching: any = false;
    GeneralInfo: any;
    index_config: any
    
    constructor(
        public api: ApiService,
        public modalController: ModalController,
        public auth: AuthService
    ) {
        if (this.auth.countryCode == 'SV') {
            this.index_config = 'general'
        } else {
            this.index_config = `${this.auth.countryCode}`
        }
        this.api.getDocument('configurations', this.index_config).then(data => {
            this.GeneralInfo = data;
        })

        // console.log(this.auth.countryCode);

        this.api.getRef('bricks').ref
            .where('type', '==', 'brick')
            .where('countryCode', '==', this.auth.countryCode)
            // .where('storeKey', '==', this.auth.storeKey)
            .orderBy('created_at')
            .limit(20)
            .get()
            .then(snapshots => {
                this.lastRequest = snapshots.docs[snapshots.docs.length - 1];

                snapshots.forEach(element => {
                    let brick = element.data();
                    brick.$key = element.id;
                    this.current_bricks.push(brick);
                });
                this.bricks = this.current_bricks;
                this.loading = false;
            })
    }

    ngOnInit() { }

    loadData(event) {
        this.api.getRef('bricks').ref
            .where('type', '==', 'brick')
            .where('countryCode', '==', this.auth.countryCode)
            // .where('storeKey', '==', this.auth.storeKey)
            .orderBy('created_at')
            .limit(20)
            .startAfter(this.lastRequest.data().created_at.toDate())
            .get()
            .then(snapshots => {
                this.lastRequest = snapshots.docs[snapshots.docs.length - 1];
                snapshots.forEach(element => {
                    let brick = element.data();
                    brick.$key = element.id;
                    this.current_bricks.push(brick);
                });
                this.bricks = this.current_bricks;
                event.target.complete();
            })
    }

    selectBrick(brick) {
        this.modalController.dismiss(
            brick
        )
    }


    search(e) {
        this.loading = true;

        let query = e.detail.value;
        if (query !== '') {
            const client = algoliasearch(algoliaConfig.appId, algoliaConfig.apiKey);
            const index = client.initIndex('bricks');

            this.searching = true;
            this.bricks = [];
            index.search(query)
                .then(({ hits }) => {
                    hits.forEach(element => {
                        if (this.GeneralInfo.queryAlgolia == true) {
                          //console.log("this.GeneralInfo.queryAlgolia = ",this.GeneralInfo.queryAlgolia, element)
                            if (element['countryCode'] == this.auth.countryCode && element['storeKey'] == this.auth.storeKey) {
                              //console.log(element);
                                this.bricks.push(element);
                            }
                        } else {
                            if (element['countryCode'] == this.auth.countryCode) {
                              //console.log(element);

                                this.bricks.push(element);
                            }
                        }
                    });
                    this.loading = false;
                });
        } else {
            this.loading = false;
            this.searching = false;
            this.bricks = this.current_bricks;
        }
    }
}
